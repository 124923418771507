import * as Yup from "yup";

// Form validation schema
export const validationSchemaProducts = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  brandName: Yup.string().required("Brand Name is required"),
  avgUsageQuantity: Yup.number().required("Quantity is required"),
  vendorIdList: Yup.array()
    .required("Vendor is required")
    .typeError("Vendor is required"),
});

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { getDateFormatYYYYMMDD, getTimeFormat } from '../../../../../helper/Common/DateFunctions';

export default function TimeSheetTable({ data, name="", weekNumber="" }) {


  const CalculateTotalTime = () => {
      let TotalHours = 0
      data.forEach((item) => {
        TotalHours += item.totalWorkingTime
      })
      return TotalHours/60
  }

  console.log(data)

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <Typography variant='h6'>Weekly Timesheet</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">
              Name: {name}
            </TableCell>
            <TableCell align="center" colSpan={3}>
              Week Number: {weekNumber}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='left'>Day of Week</TableCell>
            <TableCell align="left">Start Time</TableCell>
            <TableCell align="left">End Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const Date = getDateFormatYYYYMMDD(row.checkedIn);
            const checkedIn = getTimeFormat(row.checkedIn)
            const checkedOut = getTimeFormat(row.checkedOut)
            return (
              <TableRow key={index}>
                <TableCell align="left">{Date}</TableCell>
                <TableCell align="left">{checkedIn}</TableCell>
                <TableCell align="left">{checkedOut}</TableCell>
              </TableRow>
            );
          })}

          <TableRow>
             <TableCell align="left">Total Hours</TableCell>
            <TableCell align="left">{`${CalculateTotalTime()} hours`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell datapan={3} />
            <TableCell datapan={3} />
            <TableCell datapan={3} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
import React, { ReactNode, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, IconButton, Rating, Stack, Typography } from "@mui/material";
import { usePaginatedLogBookServices } from "../../../hooks/api/logBook/useAllLogBook";
import { capitalizeString } from "../../../helper/Common/text";
import { CustomizedDialog } from "../../../components/Mui/feedback/Dialog";
import { ILogBookContent } from "../../../hooks/api/logBook/logbook";

import Person3Icon from "@mui/icons-material/Person3";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { getTimeFormat } from "../../../helper/Common/DateFunctions";
const SideBarActiveServices = () => {
  const { logBookServiceData } = usePaginatedLogBookServices(1, 2, true);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<ILogBookContent | null>(null);

  const handleOpenDialogClose = () => {
    setOpenDialog(false);
    setDialogData(null);
  };
  const handleOpenDialog = (data: ILogBookContent) => {
    setOpenDialog(true);
    setDialogData(data);
  };
  return (
    <Stack
      className="absolute transition-all top-16 left-0 z-10 
      bg-gray-100 bg-opacity-60 backdrop-brightness-10 
      backdrop-blur-md rounded-md p-2
      lg:p-2  
      "
    >
      <Stack
        direction={"row-reverse"}
        alignItems={"center"}
        justifyContent={"space-between"}
        spacing={1}
      >
        <Stack>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Stack>
        <Stack display={open ? "flex" : "none"}>
          <Typography variant="h6" display={"flex"} alignItems={"center"}>
            Active Services
            <span className="text-sm ml-1">
              ({logBookServiceData?.content.length})
            </span>
          </Typography>
        </Stack>
      </Stack>
      <Stack
        className={`relative 
        ${!open && " scale-0 h-0 w-0"} transition-all
        `}
      >
        {logBookServiceData?.content.map((service) => {
          return (
            <Stack
              key={service.id}
              direction={"row"}
              alignItems={"center"}
              className="cursor-pointer hover:text-gray-800 hover:bg-slate-300 rounded-md p-2"
              spacing={1}
              fontSize={"small"}
              role="button"
              onClick={() => handleOpenDialog(service)}
            >
              <Typography>{service.customerEntity.customerName}</Typography>
              <span>|</span>
              <Typography>
                {service?.salonServicesEntity?.serviceName || ""}
              </Typography>
              <span>|</span>
              <Typography className="bg-slate-300 mx-1 rounded-sm px-1">{capitalizeString(service?.status)}</Typography>
            </Stack>
          );
        })}
      </Stack>
      <SideBarDialog
        handleOpenDialogClose={handleOpenDialogClose}
        openDialog={openDialog}
        dialogData={dialogData}
      />
    </Stack>
  );
};

interface ISideBarDialogProps {
  openDialog: boolean;
  handleOpenDialogClose: () => void;
  dialogData: ILogBookContent | null;
}

const SideBarDialog = ({
  openDialog,
  handleOpenDialogClose,
  dialogData,
}: ISideBarDialogProps) => {
  if (!dialogData) return <></>;
  return (
    <CustomizedDialog
      openDialog={openDialog}
      handleCloseDialog={handleOpenDialogClose}
      title={
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Typography>
            {dialogData.customerEntity.customerName}'s Services
          </Typography>
        </Stack>
      }
      disableClose={false}
      icon={<Person3Icon />}
      maxWidth="sm"
      isActionButtonRequired={false}
      actionButtons={<></>}
    >
      <Stack spacing={1}>
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Person3Icon />
          <Typography variant="h5">
            {dialogData.customerEntity.customerName}
          </Typography>
        </Stack>

        <Divider />
        <Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <ContentCutIcon />
            <Typography variant="h6" gutterBottom>
              Service Info |
              <span className="bg-slate-300 mx-1 rounded-sm px-1">
                {capitalizeString(dialogData?.status)}
              </span>
            </Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Stack>
            <Stack>
              <TextAndDescription
                label="Service Name:"
                description={dialogData?.salonServicesEntity?.serviceName}
              />

              <TextAndDescription
                label="Service Start Time:"
                description={getTimeFormat(dialogData?.startTime)}
              />
              <TextAndDescription
                label="Service End Time(ETC):"
                description={getTimeFormat(dialogData?.endTime)}
              />
            </Stack>
          </Stack>
          <Stack>
            <TextAndDescription
              label="Staff name:"
              description={dialogData?.salonStaffEntity.name}
            />
            <TextAndDescription
              label="Staff Gender:"
              description={dialogData?.salonStaffEntity.gender}
            />
            <TextAndDescription
              label="Staff Rating:"
              description={
                <div>
                  <Rating
                    name="read-only"
                    value={dialogData?.salonStaffEntity.overAllRating}
                    readOnly
                  />
                </div>
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </CustomizedDialog>
  );
};

const TextAndDescription = ({
  label,
  description,
}: {
  label: string;
  description: string | number | ReactNode;
}) => {
  return (
    <Stack direction={"row"} spacing={1} alignItems={"center"}>
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="subtitle1" sx={{ textDecoration: "underline" }}>
        {description}
      </Typography>
    </Stack>
  );
};

export default SideBarActiveServices;

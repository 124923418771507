import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SALON_ONBOARDING_ENDPOINT, UPLOAD_LOGO_ENDPOINT } from "../../../../../api/salon-api/salon-service";
import { setLogo } from "../../../../../redux/feature/uploadLogoSlice";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { setDetails } from "../../../../../redux/feature/salonDetailsSlice";
import { setPaymentQR } from "../../../../../redux/feature/uploadPaymentSlice";

export default function Payment() {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const PaymentQR = useSelector((state) => state.paymentQR);
  const SalonData = useSelector((state) => state.SalonDetails.Salon_data) 

  console.log(SalonData)

  const handleLogoChange = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.warn("Please select a Payment QR image!");
      return;
    }

    if (!SalonData?.id) {
      console.log(SalonData)
      toast.warn("Salon ID is missing!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("salonId", SalonData.id);

    console.log("File uploaded for Adding Image: ", formData);

    try {
      const response = await axiosPrivate({
        method: SalonData?.paymentS3Path ? "PUT" : "POST",
        url: `${UPLOAD_LOGO_ENDPOINT}/${SalonData?.paymentS3Path ? `update-paymentQR/${SalonData.id}` : 'upload-paymentQR'}`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const imageUrl = response.data.presignedUrl.split("?")[0];
      dispatch(setPaymentQR(imageUrl));
      toast("QR Image Uploaded");
      GetDetails();
    } catch (error) {
      console.error("Error Uploading Payment QR Image:", error);
      toast("Error Uploading QR Image");
    }
  };

  const GetDetails = async () => {
    try {
      const response = await axiosPrivate({
        method: 'GET',
        url: `${SALON_ONBOARDING_ENDPOINT}/${SalonData?.id}`,
      });
      dispatch(setDetails(response.data));
    } catch (error) {
      console.error('Error fetching Salon Details:', error);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("logo-upload").click();
  };

  return (
    <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
      <Paper style={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography color="textSecondary" variant="h5" align="center">
                  Upload Payment QR Image
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item>
                <input
                  type="file"
                  id="logo-upload"
                  style={{ display: "none" }}
                  onChange={handleLogoChange}
                />
                <Button
                  onClick={handleUploadClick}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 3 }}
                >
                  <DriveFolderUploadIcon sx={{ mr: 1 }} /> {SalonData?.paymentS3Path ? "Update QR Image" : "Upload QR Image"}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Typography
          style={{ margin: "40px 16px" }}
          color="textSecondary"
          align="center"
        >
          Current QR Code
        </Typography>

        <Stack justifyContent={"center"} alignItems={"center"}>
          <Stack className="min-w-[300px] max-w-[400px] min-h-[300px] max-h-[400px] flex justify-center items-center border-4 border-gray-400 rounded-md p-4">
            <img src={PaymentQR.paymentQRCode} className="img rounded" alt="Smart Salon Bot"/>
          </Stack>
        </Stack>

        <Typography
          style={{ marginBottom: "40px" }}
          color="textSecondary"
          align="center"
        >
          The QR Code Image size should be under 1 Mb
        </Typography>
      </Paper>
    </Box>
  );
}

import React from 'react'

function SectionRight() {
  return (
    <div className='w-1/2 overflow-hidden hidden sm:flex'>
        <div className='w-1/2'>
        <div className=' h-2/3 p-2'><img src='/photo4.png' className='img object-cover' /></div>
      <div className=' h-1/3 p-2'><img src='/photo2.png' className='img object-cover' /></div>
        </div>
        <div className='w-1/2 '>
      <div className=' h-1/3 p-2'><img src='/photo3.png' className='img object-cover' /></div>
      <div className=' h-2/3 p-2'><img src='/photo1.png' className='img object-cover' /></div>
        </div>



    </div>
  )
}

export default SectionRight

import React from 'react'
import Header from '../../../../components/SalonStaffComponent/Header'

function EmployeeManagement() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const subTabs = [
    {
      label : "Add/Remove Employee",
      to : "/adminDashboard/",
    },
    {
      label : "Time Sheets",
      to : "/adminDashboard/timeSheets",
    },
    {
      label : "Payouts",
      to : "/adminDashboard/payouts",
    },
    {
      label : "Employee Feedback",
      to : "/adminDashboard/employeeFeedback",
    },
  ]

  return (
    <Header onDrawerToggle={handleDrawerToggle} title='Employee Management' subTabs={subTabs}/>
  )
}

export default EmployeeManagement

import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
export default function PrinterSettings() {
  return (
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        {/* <Paper style={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
          >
            <Toolbar>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <SearchIcon color="inherit" style={{ display: 'block' }} />
                </Grid>
                <Grid item xs>
                  <TextField
                    fullWidth
                    placeholder="Search by email address, phone number, or employee ID"
                    InputProps={{
                      disableUnderline: true,
                      style: { fontSize: 'default' },
                    }}
                    variant="standard"
                  />
                </Grid>
                <Grid item>
                  <Button variant="contained" style={{ marginRight: 1 }}>
                    Add Employee
                  </Button>
                  <Tooltip title="Reload">
                    <IconButton>
                      <RefreshIcon color="inherit" style={{ display: 'block' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Typography style={{ margin: '40px 16px' }} color="textSecondary" align="center">
            No Employee available
          </Typography>
        </Paper> */}
      </Box>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { jwtDecode } from "jwt-decode";

// Define an interface for the auth state

export interface IData {
  email: string;
  exp: number;
  gender: string;
  iat: number;
  id: number;
  mobile: number;
  name: string;
  status: string;
  sub: string;
  userRole: string;
  uuid: number;
  clientId?: string;
  salonId: number;
}

export interface AuthState {
  auth?: {
    email: string;
    token: string;
    data?: IData | null;
  };
  email?: string;
  token?: string;
  data?: IData | null;
  salonId?: number;
}

// Use the interface for initial state
const initialState: AuthState = {
  email: "",
  token: "",
  data: null,
};

const STORE_NAME: {
  AUTH: string;
} = {
  AUTH: "auth_admin",
};

const authSlice = createSlice({
  name: STORE_NAME.AUTH,
  initialState,
  reducers: {
    setLogin: (state: AuthState, action: PayloadAction<AuthState>) => {
      // Spread the state and overwrite properties with payload
      return {
        ...state,
        ...action.payload,
        data: jwtDecodeFn(
          action.payload.token as string,
          action.payload as IData
        ) as IData,
      };
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setLogin } = authSlice.actions;

function jwtDecodeFn(token: string, data: IData) {
  const _data = {
    salonId: data.salonId,
    clientId: "",
    userRole: "SALON",
    sub: "",
  };
  try {
    return jwtDecode(token);
  } catch (error) {
    return _data;
  }
}

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import PropTypes from 'prop-types';

export const CustomizedDialog = ({
  openDialog,
  handleCloseDialog,
  title,
  disableClose,
  icon,
  children,
  isActionButtonRequired = false,
  actionButtons,
  ...props
}) => {
  return (
    <div>
      <Dialog open={openDialog} aria-labelledby='form-dialog-main' fullWidth={true} {...props}>
        <DialogTitle id='form-dialog-title' sx={{ p: 0, ml: 2 }}>
          <FormHeader
            id={title}
            title={title}
            onClose={handleCloseDialog}
            disableClose={disableClose}
            icon={icon}
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ m: 2, p: 0 }}>{children}</DialogContent>
        {isActionButtonRequired && <DialogActions sx={{ mb: 2 }}>{actionButtons}</DialogActions>}
      </Dialog>
    </div>
  );
};

CustomizedDialog.propTypes = {
  openDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  title: PropTypes.string,
  disableClose: PropTypes.bool,
  icon: PropTypes.node,
  children: PropTypes.node,
  isActionButtonRequired: PropTypes.bool,
  actionButtons: PropTypes.node,
};

export function FormHeader({
  id = 'close',
  title = 'Form Title',
  onClose = () => console.log('No callback function assigned'),
  disableClose = false,
  icon = '',
  typProps,
}) {
  return (
    <Stack direction={'row'} justifyContent='space-between' alignItems='center' px={1}>
      <Stack>
        <Typography
          component='h6'
          variant='h6'
          // color='primary'
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          {...typProps}
        >
          <Stack mr={1}>{icon}</Stack>
          <div>{title}</div>
        </Typography>
      </Stack>
      <Stack>
        <IconButton
          aria-label='close'
          id={id}
          onClick={onClose}
          size='large'
          // color='secondary'
          disabled={disableClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}

FormHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  disableClose: PropTypes.bool,
  icon: PropTypes.node,
  typProps: PropTypes.object,
};

import { configureStore } from "@reduxjs/toolkit";
import customerCredSlice from "../feature/customerCredSlice";
import employeeCredSlice from "../feature/employeeSlice";
import customer from "../feature/customer";
import common from "../feature/commonSlice";
import FeedbackSlice from "../feature/feedbackSlice";
import EmployeeTimeSheetSlice from "../feature/employeeTimesheetSlice";
import { authReducer } from "../feature/authSlice";
import salonServiceSlice from "../feature/salonServiceSlice";
import UploadLogoSlice from "../feature/uploadLogoSlice";
import SalonDetailsSlice from "../feature/salonDetailsSlice";
import logBookService from "../feature/logBookServiceSlice";
import inventoryService from "../feature/inventoryService";
import OrderBookSlice from "../feature/orderBookSlice";
import UploadPaymentSlice from "../feature/uploadPaymentSlice"
import SelectedServiceSlice from "../feature/selectedService";
export const Store = configureStore({
  reducer: {
    CustomerCred: customerCredSlice,
    Employee: employeeCredSlice,
    customers: customer,
    auth: authReducer,
    Feedback: FeedbackSlice,
    Timesheet: EmployeeTimeSheetSlice,
    services: salonServiceSlice,
    UploadLogo: UploadLogoSlice,
    SalonDetails: SalonDetailsSlice,
    OrderBook : OrderBookSlice,
    common,
    logBookService,
    inventoryService,
    paymentQR : UploadPaymentSlice,
    selectedService: SelectedServiceSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default Store;

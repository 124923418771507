import { createSlice } from "@reduxjs/toolkit";

export const OrderBookSlice = createSlice({
    name: "Order-Book",
    initialState: null,
    reducers: {
        setOrderBook: (state, action) => {
            return action.payload;
        },
    },
});

export const { setOrderBook } = OrderBookSlice.actions;

export default OrderBookSlice.reducer;

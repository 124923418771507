import { createSlice } from "@reduxjs/toolkit";
import { IInitialLogBookService } from "./slice";

const initialState: IInitialLogBookService = {
  allLogBook: null,
};

export const logBookService = createSlice({
  name: "logBookService",
  initialState: initialState,
  reducers: {
    setLogBookServiceData: (state: IInitialLogBookService, action) => {
      state.allLogBook = action.payload;
    },
  },
});

export const { setLogBookServiceData } = logBookService.actions;
export default logBookService.reducer;

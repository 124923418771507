import React, { useEffect } from "react";
import {
  CircularProgress,
  Stack,
} from "@mui/material";
import { Field, FormikProps } from "formik";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import { TextField } from "formik-mui";
import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";

import { VENDOR_FIELD_NAMES } from "../formModal/vendorInitialValue";
// icons
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EmailIcon from "@mui/icons-material/Email";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import AutoCompleteFormik from "../../../../../../components/Mui/Formik/AutoComplete";
import HomeIcon from "@mui/icons-material/Home";
import useDebounce from "../../../../../../hooks/useDebounce";
import { apiGetAddress } from "../../../../../../api/common";
import { IVendorEntity } from "../../../SystemManagement/ConfigureServices/types/configService";

interface IValueAddress {
  county: string;
  state: string;
  state_district: string;
}

interface List {
  label: string;
  value: IValueAddress;
}
interface IAddress {
  isLoading: boolean;
  addressList: List[];
}

interface IProps {
  formikProps: FormikProps<IVendorEntity>;
}

const VendorFormUI = ({ formikProps }: IProps) => {
  const [addressList, setAddressList] = React.useState<IAddress | null>(null);

  const [addressChangeValue, setAddressChangeValue] =
    React.useState<string>("");

  const { setFieldValue } = formikProps;

  const debouncedValue = useDebounce(addressChangeValue, 1000);

  const handleAddressSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: List | null
  ) => {
    setFieldValue(VENDOR_FIELD_NAMES.address, value?.label || addressChangeValue);

    const city = value?.value?.county;
    const state = value?.value?.state;
    setFieldValue(VENDOR_FIELD_NAMES.city, city || "");
    setFieldValue(VENDOR_FIELD_NAMES.state, state || "");
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setAddressChangeValue(value);
    setFieldValue(VENDOR_FIELD_NAMES.address, value);
  };

  const getAddressList = async () => {
    if (!debouncedValue) return;
    setAddressList({ isLoading: true, addressList: [] });
    try {
      const response = await apiGetAddress(debouncedValue);
      
      const data = response?.data.map((ele: any) => {
        return { label: ele.display_name, value: ele.address };
      });

      setAddressList({ isLoading: false, addressList: data });

      console.log(response, "this_is_response");

    } catch (error) {
      console.error("Error fetching address list:", error);
    }
  };

  useEffect(() => {
    console.log({ debouncedValue, addressChangeValue }, "debouncedValue");
    getAddressList();
  }, [debouncedValue]);

  return (
    <div>
      <Stack py={1} flexDirection={"column"} spacing={2}>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter vendor name"
            label="Vendor Name"
            type="text"
            name={VENDOR_FIELD_NAMES.name}
            id={VENDOR_FIELD_NAMES.name}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<ShoppingCartIcon />)}
          />

          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={AutoCompleteFormik}
            list={addressList?.addressList || []}
            isLoading={addressList?.isLoading}
            handleSelect={handleAddressSelect}
            onChange={handleAddressChange}
            placeholder="Search your address"
            label="Address"
            type="text"
            name={VENDOR_FIELD_NAMES.address}
            id={VENDOR_FIELD_NAMES.address}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(
              addressList?.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <HomeIcon />
              )
            )}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter vendor email"
            label="Vendor Email"
            type="email"
            name={VENDOR_FIELD_NAMES.email}
            id={VENDOR_FIELD_NAMES.email}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<EmailIcon />)}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter vendor city"
            label="Vendor City"
            type="text"
            name={VENDOR_FIELD_NAMES.city}
            id={VENDOR_FIELD_NAMES.city}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<LocationCityIcon />)}
          />
          <Field
            fullWidth
            variant={MUI_VARIANT}
            size={MUI_SIZE}
            component={TextField}
            placeholder="Enter vendor state"
            label="Vendor state"
            type="text"
            name={VENDOR_FIELD_NAMES.state}
            id={VENDOR_FIELD_NAMES.state}
            sx={{
              height: MUI_TEXT_FIELD_HIGHT,
            }}
            InputProps={FieldIcon(<PublicIcon />)}
          />
        </Stack>
      </Stack>
    </div>
  );
};

export default VendorFormUI;

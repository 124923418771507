import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allCustomers: [],
};

export const customer = createSlice({
  name: 'Customers',
  initialState: initialState,

  reducers: {
        /**
     * Sets all customers in the state to the provided payload.
     *
     * @param {Object} state - The current state object
     * @param {Object} action - The action object containing the payload
     */
    setAllCustomers: (state, action) => {
      state.allCustomers = action.payload;
    },
  },
});

export const { setAllCustomers } = customer.actions;
export default customer.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const UploadPaymentSlice = createSlice({
    name: "PaymentQR",
    initialState : { paymentQRCode : ""},
    reducers : {
        setPaymentQR: (state, action) => {
            state.paymentQRCode = action.payload
        }
    }
})


export const { setPaymentQR } = UploadPaymentSlice.actions ;
export default UploadPaymentSlice.reducer ;
import dayjs from 'dayjs'; // Assuming you have dayjs imported

const today = dayjs();

export const SIGNUP_FIELD_NAME = {
  PHONE_NUMBER: 'mobileNo',
  FULL_NAME: 'customerName',
  EMAIL: 'email',
  DATE: 'dob',
  GENDER: 'gender',
};

export const signupInitialValues = {
  [SIGNUP_FIELD_NAME.PHONE_NUMBER]: '',
  [SIGNUP_FIELD_NAME.FULL_NAME]: '',
  [SIGNUP_FIELD_NAME.EMAIL]: '',
  [SIGNUP_FIELD_NAME.DATE]: today,
  [SIGNUP_FIELD_NAME.GENDER]: '',
};

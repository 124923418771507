import { Avatar, Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export const CustomerProfile = () => {
  const profile = useSelector((state) => state?.CustomerCred?.token);
  console.log(profile, "customerInfo");
  const {
    customerName,
    uuid,
    mobileNo,
    email,
    gender,
    status,
    termAndCondition,
    dob,
  } = profile;
  return (
    <Stack height={'90vh'} justifyContent="center" alignItems="center">
      <Card sx={{ maxWidth: 500, boxShadow: 3, borderRadius: 2, p: 2 }}>
        <CardContent>
          {/* Avatar and Name */}
          <Stack alignItems="center" spacing={2} mb={2}>
            <Avatar sx={{ bgcolor: 'gray', width: 80, height: 80 }}>
              {customerName.charAt(0)}
            </Avatar>
            <Typography variant="h5" fontWeight="bold">
              {customerName}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {uuid}
            </Typography>
          </Stack>

          <Divider />

          {/* Profile Information */}
          <Stack spacing={2} mt={2}>
            <Typography variant="body1">
              <strong>Mobile No:</strong> {mobileNo}
            </Typography>
            <Typography variant="body1">
              <strong>Email:</strong> {email}
            </Typography>
            <Typography variant="body1">
              <strong>Gender:</strong> {gender}
            </Typography>
            <Typography variant="body1">
              <strong>Status:</strong> {status}
            </Typography>
            <Typography variant="body1">
              <strong>Terms & Condition:</strong> {termAndCondition}
            </Typography>
            <Typography variant="body1">
              <strong>Date of Birth:</strong> {dob}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

import { createSlice } from "@reduxjs/toolkit";

export interface ICommonState {
  isDrawerOpen: boolean;
  salonId: number;
}

const initialState: ICommonState = {
  isDrawerOpen: false,
  salonId: 1,
};

export const common = createSlice({
  name: "common-state",
  initialState: initialState,

  reducers: {
    setOpenDrawer: (state) => ({
      ...state,
      isDrawerOpen: !state.isDrawerOpen, // Toggle drawer state
    }),

    setSalonId: (state, action) => {
      state.salonId = action.payload;
    },
  },
});

export const { setOpenDrawer, setSalonId } = common.actions;
export default common.reducer;

export const SERVICE_CATEGORY_DATA_KEY = {
  ID: "id",
  SERVICE_NAME: "serviceName",
  SERVICE_TIME: "avgServeTime",
  SERVICE_GENDER: "gender",
  COST: "price",
  STATUS: "status",
  OPERATIONS: "",
};

export const headCellsServiceCategoryConfig = [
  {
    id: SERVICE_CATEGORY_DATA_KEY.SERVICE_NAME,
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: SERVICE_CATEGORY_DATA_KEY.SERVICE_TIME,
    numeric: false,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: SERVICE_CATEGORY_DATA_KEY.COST,
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: SERVICE_CATEGORY_DATA_KEY.SERVICE_GENDER,
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
 
  {
    id: SERVICE_CATEGORY_DATA_KEY.OPERATIONS,
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

import React from "react";
import ProductTable from "./ProductTable";
import { BaseCard } from "../../../../../components/Mui";

const Products = () => {
  return (
    <BaseCard>
      <ProductTable />
    </BaseCard>
  );
};

export default Products;

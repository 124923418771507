import { createSlice } from "@reduxjs/toolkit";
import logo from '../../assets/logo.jpeg'
export const UploadLogoSlice = createSlice({
    name: "UploadLogo",
    initialState : { logo : logo},
    reducers : {
        setLogo: (state, action) => {
           return state.logo = action.payload || logo
        }
    }
})


export const { setLogo } = UploadLogoSlice.actions ;
export default UploadLogoSlice.reducer ;
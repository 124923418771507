import React from 'react'
import Header from '../../../../components/SalonStaffComponent/Header'

export default function Reports() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const subTabs = [
    {
      label : "Summary Reports",
      to : "/adminDashboard/Reports/",
    },
    // {
    //   label : "Custom Summary Report",
    //   to : "/adminDashboard/Reports/customSummary",
    // },
    // {
    //   label : "Service Summary Report",
    //   to : "/adminDashboard/Reports/serviceSummary",
    // },
    // {
    //   label : "Employee Report",
    //   to : "/adminDashboard/Reports/employeeReport",
    // },
    // {
    //   label : "Payment Summary Report",
    //   to : "/adminDashboard/Reports/paymentSummary",
    // },
    // {
    //   label : "Customer Report",
    //   to : "/adminDashboard/Reports/customerReport",
    // },
    // {
    //   label : "Feedback Report",
    //   to : "/adminDashboard/Reports/feedbackReport",
    // },
  ]

  return (
    <Header onDrawerToggle={handleDrawerToggle} title='Reports' subTabs={subTabs}/>
  )
}


import { createSlice } from "@reduxjs/toolkit";
import { IInitialEmployee } from "./slice";

const InitialEmployee: IInitialEmployee = {
  employeeData: null,
  allEmployees: [],
};

export const EmployeeSlice = createSlice({
  name: "Employee",
  initialState: InitialEmployee,
  reducers: {
    setEmployeesData: (state: IInitialEmployee, action) => {
      //This call when we call api first time
      state.employeeData = action.payload;
    },
    setEmployees: (state: IInitialEmployee, action) => {
      if (state.employeeData === null) return;
      state.employeeData.content = [
        action.payload,
        ...state.employeeData.content,
      ];
    },

    updateEmployees: (state, action) => {
      if (state.employeeData === null) return;
      state.employeeData.content = state.employeeData.content.map(
        (employee) => {
          if (employee.id === action.payload.id) {
            return action.payload;
          }
          return employee;
        }
      );
    },
  },
});

export const { setEmployees, updateEmployees, setEmployeesData } =
  EmployeeSlice.actions;
export default EmployeeSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialFeedback = {
    allFeedback: [],
    paginationData: {
        totalElements : 0,
        page: 0,
        totalPages : 0
    }
}

export const FeedbackSlice = createSlice({
    name: "FeedBack",
    initialState : initialFeedback,
    reducers : {
        setFeedback: (state, action) => {
            state.allFeedback = action.payload.content
            state.paginationData = {totalElements : action.payload.totalElements, page: action.payload.page, totalPages: action.payload.page}
        }
    }
})


export const { setFeedback } = FeedbackSlice.actions ;
export default FeedbackSlice.reducer ;
import { createSlice } from "@reduxjs/toolkit";
import { IInitialService } from "./slice";

const InitialEmployee: IInitialService = {
  serviceData: null,
  serviceCategories: null,
  vendorData: null,
};

export const serviceSlice = createSlice({
  name: "Services",
  initialState: InitialEmployee,
  reducers: {
    setServiceData: (state: IInitialService, action) => {
      state.serviceData = action.payload;
    },
    setUpdateService(state: IInitialService, action) {
      if (state.serviceData === null) return;
      state.serviceData.content = state.serviceData?.content.map((service) => {
        if (service.id === action.payload.id) {
          return action.payload;
        }
        return service;
      });
    },
    setAddService(state: IInitialService, action) {
      if (state.serviceData === null) return;
      state.serviceData.content = [
        action.payload,
        ...state.serviceData.content,
      ];
    },
    setDeleteService(state: IInitialService, action) {
      if (state.serviceData === null) return;
      state.serviceData.content = state.serviceData.content.map((service) => {
        if (service.id === action.payload) {
          return {
            ...service,
            status: "DELETED",
          };
        }
        return {
          ...service,
        };
      });
    },

    // Service Category
    setServiceCategory(state: IInitialService, action) {
      state.serviceCategories = action.payload;
    },
    setAddServiceCategory(state: IInitialService, action) {
      if (state.serviceCategories === null) return;
      state.serviceCategories.content = [
        action.payload,
        ...state.serviceCategories.content,
      ];
    },
    setUpdateServiceCategory(state: IInitialService, action) {
      if (state.serviceCategories === null) return;
      state.serviceCategories.content = state.serviceCategories.content.map(
        (category) => {
          if (category.id === action.payload.id) {
            return { ...category, ...action.payload };
          }
          return category;
        }
      );
    },
    setDeleteServiceCategory(state: IInitialService, action) {
      if (state.serviceCategories === null) return;
      state.serviceCategories.content = state.serviceCategories.content.map(
        (category) => {
          if (category.id === action.payload) {
            return {
              ...category,
              status: "DELETED",
            };
          }
          return {
            ...category,
          };
        }
      );
    },

    // Vendor
    setAllVendor(state: IInitialService, action) {
      state.vendorData = action.payload;
    },
    setAddVendor(state: IInitialService, action) {
      if (state.vendorData === null) return;
      state.vendorData.content = [action.payload, ...state.vendorData.content];
    },
    setUpdateVendor(state: IInitialService, action) {
      if (state.vendorData === null) return;
      state.vendorData.content = state.vendorData.content.map((vendor) => {
        if (vendor.id === action.payload.id) {
          return action.payload;
        }
        return vendor;
      });
    },
    setDeleteVendor(state: IInitialService, action) {
      if (state.vendorData === null) return;
      state.vendorData.content = state.vendorData.content.map((vendor) => {
        if (vendor.id === action.payload) {
          return {
            ...vendor,
            status: "DELETED",
          };
        }
        return {
          ...vendor,
        };
      });
    },
  },
});

export const {
  setServiceData,
  setUpdateService,
  setAddService,
  setDeleteService,

  setServiceCategory,
  setAddServiceCategory,
  setUpdateServiceCategory,
  setDeleteServiceCategory,

  // Vendor
  setAllVendor,
  setAddVendor,
  setUpdateVendor,
  setDeleteVendor,
} = serviceSlice.actions;
export default serviceSlice.reducer;

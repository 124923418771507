import React from "react";
// import { AuthenticateRoutes } from "./Routes/AuthenticateRoutes.jsx";
import { RouterProvider } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { routeBase } from "./Routes/routeList";

function App() {
  const {auth} = useAuth();
  const role = auth?.data?.userRole
  return (
    <>
      <RouterProvider router={routeBase(role)} />
    </>
  );
}

export default App;

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";
const API_KEY = "AIzaSyBJ2aBf-Ju5vxtAK3aVRwSp6PgZgSKf1J4";
const VAPID_KEY =
  "BCSKX9ym72TbjZtZQb0oeKRIGsa-DoGp73OuaRKUMGbPavDAFBTI6RRDMwXuM8l_yH-yEXhFzKOUvBehww_Lv7w";

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: "smartsalonbot.firebaseapp.com",
  projectId: "smartsalonbot",
  storageBucket: "smartsalonbot.appspot.com",
  messagingSenderId: "1033755534863",
  appId: "1:1033755534863:web:2686c03d3ac1ec47e7a57a",
};

const FIREBASE_APP = initializeApp(firebaseConfig);
const FIREBASE_MESSAGING = getMessaging(FIREBASE_APP);

export { FIREBASE_APP, FIREBASE_MESSAGING };

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        return currentToken;
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

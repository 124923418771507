import * as Yup from "yup";

export const validationSchemaInventory = Yup.object().shape({
  productId: Yup.string().required("Product is required"),
  vendorId: Yup.string().required("Vendor is required"),
  quantity: Yup.number()
    .required("Quantity is required")
    .typeError("Quantity should be a number"),
  threshold: Yup.string().required("Threshold is required"),
  measurementUnit: Yup.string().required("Measurement unit is required"),
  // description: Yup.string().required("Description is required"),
});

import React from "react";
import { useNavigate } from "react-router-dom";
import { SalonLogo } from "../../../assets/img";
import Ticket from "../Checkout/ticket";

function Logout() {
  const navigate = useNavigate();

  const timeDelay = setTimeout(()=>{
    navigate("/customer/signup")
  }, 20000)

  const handleLogout = () => {
    clearTimeout(timeDelay)
    navigate("/customer/signup");
  };

  const imgUrl =
    "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?q=80&w=1911&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";


  return (
    <>
      <div
        className="bg-slate-400 h-screen w-screen flex items-center justify-center"
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundSize: "cover",
        }}
      >
        <div className="bg-gray-100 bg-opacity-55 backdrop-blur-sm p-8 rounded-md w-96 shadow-md">
          <div className="flex justify-center">
            <img
              src={SalonLogo}
              className="img h-[50px] w-[125px] sm:h-[75px] sm:w-[200px] my-5"
            />
          </div>
        <div className="flex flex-col gap-3 items-center">
          <h1 className="text-2xl sm:text-3xl font-medium">Thank for choosing us! </h1>
          {/* <Ticket /> */}
          <p className="text-xl sm:2xl">Kindly wait for your ticket number</p>
          <button
            className="bg-1 w-[170px] text-center btn px-2 py-3 text-xl font-medium transition opacity-100 duration-500 transform hover:opacity-0 hover:scale-150"
            onClick={handleLogout}
          >
            Back to Home
          </button>
        </div>
        </div>
      </div>
    </>
  );
}

export default Logout;

import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteProduct,
  usePaginatedProducts,
  useUpdateProduct,
} from "../../../../../hooks/api/inventory-service/useAllProduct";
import EnhancedTableHead from "../../../../../components/Mui/table/Table";
import { headCellsProducts } from "./productConstant";
import ProductForm from "./formUi/ProductForm";
import { IProductsContent } from "../../../../../hooks/api/inventory-service/inventoryService";

type TMode = "EDIT" | "ADD";
export interface IOpenProductForm {
  mode: TMode;
  open: boolean;
  data: null | IProductsContent;
}
const initialState: IOpenProductForm = {
  mode: "ADD",
  open: false,
  data: null,
};

const ProductTable = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [selected] = useState([]);
  const [openProductForm, setOpenProductForm] =
    useState<IOpenProductForm>(initialState);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { deleteProduct } = useDeleteProduct();
  const { updateProduct } = useUpdateProduct();

  const {
    allProducts,
    isLoading,
    currentPage: page,
    pageSize: rowsPerPage,
    setPage,
    setSize: setRowsPerPage,
  } = usePaginatedProducts(1, 5);

  useEffect(() => {
    if (!isLoading && allProducts?.content) {
      setDataLoaded(true);
    }
  }, [isLoading, allProducts]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (newPage < 1) return;
    setPage(newPage);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseProductForm = () => {
    setOpenProductForm(initialState);
  };

  const handleOpenProductForm = (
    mode: TMode,
    data: null | IProductsContent
  ) => {
    setOpenProductForm({ mode, open: true, data });
  };

  const handleDeleteProduct = async (id: number) => {
    await deleteProduct(id);
  };

  const handleChangeSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: IProductsContent
  ) => {
    console.log(row)
    const checked = event.target.checked;
    const requestBody = {
      ...row,
      status: checked ? "ACTIVE" : "INACTIVE",
      vendorIdList: row?.vendorList?.map((vendor) => vendor.id),
    } as IProductsContent;
    try {
      console.log("requestBody", requestBody);
      updateProduct(requestBody);
    } catch (error) {
      console.error("error", error);
    }
  };

  const tableInclose = (
    <Table>
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={allProducts?.content?.length}
        headCells={headCellsProducts}
      />
      <TableBody>
        {allProducts?.content?.map((row, index: number) => {
          const labelId = `product-table-${index}`;
          return (
            <TableRow
              key={labelId}
              sx={{
                bgcolor: row.status === "DELETED" ? "gray" : "white",
                cursor: "pointer",
                mx: 4,
              }}
              role="checkbox"
              tabIndex={-1}
            >
              <TableCell component="th" id={labelId} scope="row">
                {row?.name}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.avgUsageQuantity} {row?.measurementUnit?.toLowerCase()}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.vendorList?.map((item) => item.name).join(', ')}
              </TableCell>
              <TableCell component="th" id={labelId} scope="row">
                {row?.brandName}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
              >
                <Stack direction="row">
                  <Switch
                    sx={{ mx: 1 }}
                    checked={row.status === "ACTIVE"}
                    onChange={(e) => handleChangeSwitch(e, row)}
                  />
                  <ModeEditIcon
                    sx={{ mx: 1 }}
                    onClick={() => handleOpenProductForm("EDIT", row)}
                  />
                  {/* <DeleteIcon
                    color="error"
                    sx={{ mx: 1 }}
                    onClick={() => handleDeleteProduct(row.id || 0)}
                  /> */}
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Stack width={"100%"}>
      <TableContainer>
        <Toolbar>
          <Grid container spacing={2} alignItems="center" width={"45%"}>
            <Grid item>
              <SearchIcon color="inherit" style={{ display: "block" }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by Product Name"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "default" },
                }}
                variant="standard"
                size="small"
              />
            </Grid>
            <Grid item xs>
              <Chip
                label="Search"
                onClick={() => {
                  console.log("search");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" width={"55%"}>
            <Grid item xs></Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleOpenProductForm("ADD", null)}
              >
                Add Product
              </Button>
            </Grid>
          </Grid>
        </Toolbar>

        {!dataLoaded ? (
          <LinearProgress />
        ) : allProducts?.content?.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ my: 2 }}>
            No data available
          </Typography>
        ) : (
          tableInclose
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={allProducts?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ProductForm
        openProductForm={openProductForm}
        handleCloseProductForm={handleCloseProductForm}
      />
    </Stack>
  );
};

export default ProductTable;

import { TextField } from "@mui/material";
import { FieldInputProps, FormikProps } from "formik";

interface IUploadImageProps {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
}

export const UploadImage = ({ field, form, ...props }: IUploadImageProps) => {
  const { setFieldValue } = form;
  const handleChangeImg = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.target as HTMLInputElement;
    const file = target && target.files ? target.files[0] || null : null;
    if (!file) return;
    if (file) {
      setFieldValue(field.name, file);
    }
  };

  return (
    <>
      <TextField
        // {...field}
        onChange={(e) => handleChangeImg(e)}
        {...props}
      />
    </>
  );
};

import React from "react";
import Header from "../../../../components/SalonStaffComponent/Header";

export default function InventoryManagement() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const subTabs = [
    {
      label: "Products",
      to: "/adminDashboard/inventory-management/products",
    },
    {
      label: "Vendors",
      to: "/adminDashboard/inventory-management/",
    },
    {
      label: "Salon Inventory",
      to: "/adminDashboard/inventory-management/salon-inventory",
    },
  ];

  return (
    <Header
      onDrawerToggle={handleDrawerToggle}
      title="Inventory Management"
      subTabs={subTabs}
    />
  );
}

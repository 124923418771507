import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import {
  STAFF_ALL_ENDPOINT,
  STAFF_ENDPOINT,
} from "../../../../../api/salon-api/salon-service";
import { useDispatch } from "react-redux";
import {
  setEmployeesData,
  updateEmployees,
} from "../../../../../redux/feature/employeeSlice";
import { IEmployee } from "../../../../../redux/feature/slice";
import { useAuth } from "../../../../../hooks/useAuth";

interface Employee {
  id: number;
  name: string;
  // Define other properties of Employee here
}

interface PaginatedEmployeesResponse {
  data: IEmployee[];
  // Define other pagination-related properties if needed
}

interface PaginatedEmployeesHook {
  isLoading: boolean;
  error: Error | null;
  currentPage: number;
  pageSize: number;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
}

const usePaginatedEmployees = (
  initialPage = 1,
  initialSize = 5
): PaginatedEmployeesHook => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { auth } = useAuth();
  const salonId = auth?.data?.salonId

  const fetchEmployees = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = {
        page: currentPage,
        size: pageSize,
        salonId
      };

      const response = await axiosPrivate.post<PaginatedEmployeesResponse>(
        STAFF_ALL_ENDPOINT,
        params
      );

      if (response.status === 200) {
        dispatch(setEmployeesData(response.data));
      } else {
        setError(new Error("Failed to fetch employees"));
      }
    } catch (error: any) {
      console.error("Error fetching employees:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
  };
};

export default usePaginatedEmployees;

export const useUpdateEmployee = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const updateEmployee = async (data: IEmployee) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.put<IEmployee>(
        `${STAFF_ENDPOINT}/${data.id}`,
        { ...data }
      );

      if (response.status === 200) {
        dispatch(updateEmployees(response.data));
        // Consider dispatching an action to update employee data in Redux store
        // dispatch(setEmployeesData([response.data])); // Example using an action
      } else {
        setError(new Error("Failed to update employee"));
      }
    } catch (error: any) {
      console.error("Error updating employee:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateEmployee,
  };
};

import { IProductsContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { IOpenProductForm } from "../ProductTable";

export const PRODUCT_FIELD_NAMES = {
  name: "name",
  description: "description",
  brandName: "brandName",
  avgUsageQuantity: "avgUsageQuantity",
  productCatId: "productCatId",
  status: "status",
  selectedVendor: "selectedVendor",
  vendorIdList: "vendorIdList",
};

export const productInitialValue = (formInfo: IOpenProductForm) => {
  const formData = formInfo.mode === "EDIT" ? formInfo.data : null;
  return {
    name: formData?.name || "",
    description: formData?.description || "",
    brandName: formData?.brandName || "",
    avgUsageQuantity: formData?.avgUsageQuantity || 0,
    // TODO: Add product category
    productCatId: 1,
    status: formData?.status || "ACTIVE",
    vendorIdList: formData?.vendorList
      ? formData?.vendorList.map((ele) => {
          return ele.id;
        })
      : [],
  } as IProductsContent;
};

import { Box, Paper } from "@mui/material";
import PropTypes from "prop-types";
/**
 * Renders a base card component with a main box that wraps the children.
 *
 * @param {Object} props - The props object containing the children to render.
 * @param {ReactNode} props.children - The children to render inside the main box.
 * @return {JSX.Element} The base card component.
 */
const BaseCard = ({ children, sx = {}, psx = {}, ...props }) => {
  return (
    <div>
      <Box
        component="main"
        sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1", ...sx }}
        {...props}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 1,
            ...psx,
          }}
        >
          {children}
        </Paper>
      </Box>
    </div>
  );
};

BaseCard.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
  psx: PropTypes.object,
};

export default BaseCard;

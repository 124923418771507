import { InventoryContent } from "../../../../../../hooks/api/inventory-service/inventoryService";
import { IOpenInventoryForm } from "../InventoryTable";

export const INVENTORY_FIELD_NAMES = {
  salonId: "salonId",
  productId: "productId",
  vendorId: "vendorId",
  quantity: "quantity",
  threshold: "threshold",
  measurementUnit: "measurementUnit",
  status: "status",
  description: "description",
};

export const inventoryInitialValue = (formInfo: IOpenInventoryForm) => {
  const formData = formInfo.mode === "EDIT" ? formInfo.data : null;
  return {
    description: formData?.description || "",
    quantity: formData?.quantity || 0,
    threshold: formData?.threshold || 0,
    productId: formData?.productEntity.id || 0,
    vendorId: formData?.vendorEntity?.id || 0,
    measurementUnit: formData?.measurementUnit || "MILLILITER",
  } as InventoryContent;
};

// react
import { useState } from 'react';

import PropTypes from 'prop-types';

// MUI
import {
  FormControl,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from '@mui/material';

// components
import { BaseCard } from '../../../../../components/Mui';
import { EMP_FEEDBACK_DATA, empRatings } from './PayoutConstants';
import { CustomizedDialog } from '../../../../../components/Mui/feedback/Dialog';

import { useSelector } from 'react-redux';

// icons
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { FeedbackEmpTable } from './PayoutDetails';

export default function EmployeeFeedback() {
  /**
   * Todo: API integration pending here 23/04/2024
   *  1. By Default 1st employee will be selected
   *  2. User Can select employee from dropdown
   * !Question - Current Redux state not getting required data like emp ratings id etc.
   */

  const [openDialogue, setOpenDialogue] = useState(false);

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };
  const handleDialogueOpen = (employee) => {
    // this employee will be selected
    // console.log(employee)
    setOpenDialogue(true);
  };


  return (
    <BaseCard sx={{ p: 0 }}>
      <FeedbackEmpTable handleDialogueOpen={handleDialogueOpen} />

      <CustomizedDialog
        openDialog={openDialogue}
        handleCloseDialog={handleDialogueClose}
        title={'Payout Details'}
        // disableClose={buttonLoader}
        // icon={<SupervisedUserCircleIcon />}
        maxWidth='sm'
        isActionButtonRequired={false}
      >
        {/* EmpSelector */}
        {/* <EmpSelect /> */}
        {/* Ratings */}
        <div className=' flex items-center w-full max-w-[450px] min-w-72 justify-center  mx-auto flex-col md:flex-row '>
          <Stack direction={'column'}>
            <ResponsiveRatingSummary />
          </Stack>
          {/* <OverAllRating label={EMP_FEEDBACK_DATA.OVER_ALL_RATING} value={4.5} /> */}
        </div>
      </CustomizedDialog>
    </BaseCard>
  );
}

/**
 * This function JSX may be use in future
 * Renders a component for selecting an employee from a list.
 *
 * @return {JSX.Element} The JSX element for the employee selection component.
 * @deprecated use {@link EmpSelect}
 */
const EmpSelect = () => {
  const allEmployees = useSelector((state) => state.Employee.allEmployees) || [];

  const [employee, setEmployee] = useState('');

  /**
   * Updates the selected employee based on the selected value from the event target.
   *
   * @param {Event} event - The event object containing the target value.
   * @return {void} This function does not return anything.
   */
  const handleChange = (event) => {
    setEmployee(event.target.value);
  };
  return (
    <Stack>
      <FormControl sx={{ m: 1, width: 300 }} size='small'>
        <InputLabel id='employees-payout'>{EMP_FEEDBACK_DATA.LABEL}</InputLabel>

        <Select value={employee} label={EMP_FEEDBACK_DATA.LABEL} onChange={handleChange}>
          {allEmployees.map((item) => (
            <MenuItem key={item.empId} value={item?.empId}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

/**
 * Renders a responsive rating summary component.
 *
 * @return {JSX.Element} The rendered rating summary component.
 */
const ResponsiveRatingSummary = () => {
  return (
    <>
      {empRatings.map((rating) => (
        // <Ratings key={rating.label} label={rating.label} value={rating.value} />
        <Stack spacing={4} justifyContent={'space-between'} key={rating.label} direction={'row'}>
        <Typography variant='body1'  >{rating.label}</Typography>
        <Typography variant='body1' align={'center'}>:</Typography>
        {/* <Typography variant='body1' align={'left'}>{rating.label}</Typography> */}
        </Stack>
      ))}
    </>
  );
};

// const Ratings = ({ label = '', value }) => {
//   return (
//     <Stack direction={'row'} justifyContent={'space-between'} m={0.5}>
//       <Stack>
//         <Typography component='body2' mr={1}>
//           {label}
//         </Typography>
//       </Stack>
//       <Rating readOnly name={label} value={value} precision={0.5} />
//     </Stack>
//   );
// };

// Ratings.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.number,
// };

// const OverAllRating = ({ label = '', value }) => {
//   return (
//     <Stack>
//       <Typography component='h3' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
//         {EMP_FEEDBACK_DATA.OVER_ALL_RATING}
//       </Typography>
//       <div className='m-1 rounded-full border h-32 w-32 flex justify-center items-center flex-col'>
//         <Stack>
//           <Typography component='body2' sx={{ textAlign: 'center' }}>
//             {EMP_FEEDBACK_DATA.OVER_ALL}
//           </Typography>
//         </Stack>
//         <Typography>{value}</Typography>
//         <Rating name={label} value={value} readOnly size={'small'} precision={0.5} />
//       </div>
//     </Stack>
//   );
// };

// OverAllRating.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.number,
// };

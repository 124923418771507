import { createSlice } from "@reduxjs/toolkit";

const initialState = { isCustomer: false };

export const customerCredSlice = createSlice({
  name: "CustomerCred",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    addUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    deleteUser: (state) => {
      return initialState;
    },
  },
});

export const { addUser, deleteUser, setUser } = customerCredSlice.actions;
export default customerCredSlice.reducer;

import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Chip } from "@mui/material";
import { BaseCard } from "../../../../components/Mui";
import SendIcon from "@mui/icons-material/Send";
import SendReportForm from "./FormModal/SendReportForm";
import { 
  CUSTOMER_REPORTS, 
  DAY_END_SUMMARY_REPORTS, 
  EMPLOYEE_REPORTS, 
  PAYMENT_REPORTS, 
  SERVICE_REPORTS
 } from "../../../../api/salon-api/salon-service";

export default function DayEndSummary() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentEndpoint, setCurrentEndpoint] = useState("");

  function handleIsOpen(endpoint = "") {
    setCurrentEndpoint(endpoint);
    setIsOpen(!isOpen);
  }

  return (
    <BaseCard sx={{ p: 0 }}>
      {REPORTS_TYPES.map((item, index) => (
        <React.Fragment key={index}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ border: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: '10px' }}
        >
          <Toolbar sx={{ display: "flex", flexWrap: "wrap" }}>
            <Grid container spacing={2} alignItems="center" width="45%">
              <Grid item>
                <Typography
                  style={{ margin: "4px" }}
                  variant="h6"
                  color="textSecondary"
                  align="center"
                >
                  {item.label}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="right" width="55%">
              <Grid item>
                <Chip
                  label="Send Report"
                  sx={{ cursor: "default", m: 2 }}
                  color="primary"
                  icon={<SendIcon fontSize="small" />}
                  onClick={() => handleIsOpen(item.endpoint)}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
          <SendReportForm 
            isOpen={isOpen} 
            handleIsOpen={handleIsOpen} 
            REPORT_API_ENDPOINT={currentEndpoint} 
          />
        </React.Fragment>
      ))}
    </BaseCard>
  );
}

export const REPORTS_TYPES = [
  {
    label: "Day-End Summary Report",
    endpoint: DAY_END_SUMMARY_REPORTS,
  },
  {
    label: "Customer Summary Report",
    endpoint: CUSTOMER_REPORTS,
  },
  {
    label: "Service Summary Report",
    endpoint: SERVICE_REPORTS,
  },
  {
    label: "Employee Summary Report",
    endpoint: EMPLOYEE_REPORTS,
  },
  {
    label: "Payment Summary Report",
    endpoint: PAYMENT_REPORTS,
  },
];

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PropTypes from 'prop-types';

// Formik
import { useFormikContext } from 'formik';
import { TimePicker } from '@mui/x-date-pickers';

export const DatePickerField = ({ field, form, textFieldProps = {}, ...other }) => {
  const { name, value } = field;
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();

  const handleDateChange = (date) => {
    setFieldValue(name, date);
    setFieldTouched(name, true, false);
  };

  let isError = touched[name] && errors[name];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          slotProps={{ textField: { ...textFieldProps, error: isError } }}
          sx={{ width: '100%' }}
          id='date-picker-dialog'
          value={value}
          onChange={handleDateChange}
          {...other}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.object,
  index: PropTypes.number,
  multiFieldArray: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textFieldProps: PropTypes.object,
  variant: PropTypes.string,
  error: PropTypes.bool,
};

export function TimePickerField({ field, form, textFieldProps = {}, ...other }) {
  const { name, value } = field;
  const { setFieldValue, touched, errors } = form;
  const { setFieldTouched } = useFormikContext();

  const handleDateChange = (date) => {
    setFieldValue(name, date);
    setFieldTouched(name, true, false);
  };

  let isError = touched[name] && errors[name];
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          slotProps={{ textField: { ...textFieldProps, error: isError } }}
          id='time-picker-dialog'
          value={value}
          onChange={handleDateChange}
          {...other}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

TimePickerField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  helperText: PropTypes.string,
  meta: PropTypes.object,
  index: PropTypes.number,
  multiFieldArray: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textFieldProps: PropTypes.object,
  variant: PropTypes.string,
  error: PropTypes.bool,
};

import axios from "axios";

export const apiGetAddress = async (address: any) => {
  try {
    const response = axios.get(
      `https://nominatim.openstreetmap.org/search?q=${address}&addressdetails=1&format=json&limit=5`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

import React, { useState } from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HomeIcon from "@mui/icons-material/Home";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";

import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IMG_URL } from "../../../constants/baseURL";
function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const Logo = useSelector((state) => state.UploadLogo.logo);
  const SalonDetails = useSelector((state) => state?.SalonDetails?.Salon_data);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const IMG_URL_LOGO = SalonDetails?.logoS3Path
    ? `${IMG_URL.BASE_SERVICE_IMG}${SalonDetails.logoS3Path}`
    : Logo;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const getSalonType = localStorage.getItem("current-select");
  const customerUrl = [
    {
      name: "Home",
      url: `/customer/service/${getSalonType ? getSalonType : "male"}`,
      icon: <HomeIcon />,
    },
    {
      name: "Services",
      url: "/customer",
      icon: <ContentCutIcon />,
    },
    {
      name: "Order History",
      url: "/customer/order-history",
      icon: <ShoppingCartIcon />,
    },
    // {
    //   name: "Checkout",
    //   url: "/customer/checkout",
    //   icon: <CheckCircleIcon />,
    // },
    {
      name: "Profile",
      url: "/customer/profile",
      icon: <PersonIcon />,
    },
    {
      name: "Logout",
      url: "/customer/logout",
      icon: <LogoutIcon />,
    },
  ];
  // SalonDetails.address
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isMobile ? "space-between" : "space-between"}
          width={"100%"}
        >
          <Box display="flex" alignItems="center">
            <img
              src={IMG_URL_LOGO}
              alt="Logo"
              style={{ width: 40, height: 40, marginRight: 8 }}
            />
            <Typography variant="h6">Smart Salon Bot</Typography>
          </Box>

          {isMobile && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="Hold/Unhold Status"></Tooltip>
              <IconButton aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
            </Stack>
          )}

          {!isMobile && (
            <Box display="flex" alignItems="center">
              {customerUrl.map((item, index) => (
                <Button
                  key={index}
                  color="inherit"
                  component={Link}
                  to={item.url}
                  startIcon={item.icon}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          )}
        </Box>
        {isMobile && (
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{ sx: { width: 200 } }}
          >
            {customerUrl.map((item, index) => (
              <NavLink
                key={index}
                to={item.url}
                onClick={toggleDrawer(false)}
                className={({ isActive }) => {
                  return isActive ? "active" : "";
                }}
              >
                <ListItemButton key={index}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </NavLink>
            ))}
          </Drawer>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;

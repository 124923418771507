import { useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';

// MUI
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Rating, Stack, TextField, Typography } from '@mui/material';

// components
import { EMP_DATA_KEY, headCellsEmpFeedBack, rowsEmployeeFeedBack, EditPayout } from './PayoutConstants';

import EnhancedTableHead from '../../../../../components/Mui/table/Table';

// helper
import { getComparator, stableSort } from '../../../../../helper';

// icons
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';

export function FeedbackEmpTable({ handleDialogueOpen }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modal, setModal] = useState({
    isOpen: false
  })

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsEmployeeFeedBack.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(rowsEmployeeFeedBack, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (<>
    <Stack width={'100%'}>
      <TableContainer>
        <Toolbar sx={{display: "flex", flexWrap: "wrap"}}>
          <Grid container spacing={2} alignItems='center' width={'45%'}>
            <Grid item>
              <SearchIcon color='inherit' style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder='Search by Name or Employee ID'
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: 'default' },
                }}
                variant='standard'
                size='small'
              // onChange={(e) => setValue(e.target.value)}
              />
            </Grid>
            <Grid item xs>
              <Chip label='Search' />
            </Grid>
          </Grid>
          <Grid container justifyContent={'right'} width={'55%'}>
            {/* <Grid item xs={3}>
              <Chip label='Export' width={'25px'} color='primary' icon={<FileDownloadSharpIcon/>} />
            </Grid> */}
          </Grid>
        </Toolbar>
        <Table >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowsEmployeeFeedBack.length}
            headCells={headCellsEmpFeedBack}
          />
          <TableBody>
            {visibleRows.length > 0 && visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `employee-table-${index}`;

              return (
                <TableRow
                  hover
                  role='checkbox'
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  size={'medium'}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell component='th' id={labelId} scope='row' padding='none'>
                    {row.name}
                  </TableCell>

                  <TableCell align='left'>{row[EMP_DATA_KEY.EMPLOYEE_CODE]}</TableCell>
                  <TableCell align='left'>{row[EMP_DATA_KEY.SALARY]}</TableCell>
                  <TableCell align='left'>{row[EMP_DATA_KEY.ADDRESS]}</TableCell>
                  <TableCell align='right'>
                    <RemoveRedEyeIcon onClick={(event) => handleDialogueOpen(event, row.id)}/>
                  </TableCell>
                  <TableCell align='right'>
                    <EditSharpIcon color='success' onClick={() => setModal({...modal, isOpen: true})}/>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={rowsEmployeeFeedBack.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>

    <Dialog
      open={modal.isOpen}
      onClose={() => setModal({ ...modal, isOpen: false })}
      fullWidth={true}
      maxWidth={"sm"}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          // AddEmployees();
          // setSpecialityInsert([]);
          setModal({ ...modal, isOpen: false })
        },
      }}
    >
      <DialogTitle>Edit Payout Details</DialogTitle>
      <DialogContent dividers>
        <DialogContentText >
          * fields are necessary
        </DialogContentText>
        {
          EditPayout.map((item, index) =>
            <TextField
              autoFocus
              required={item.required}
              margin="dense"
              id={item.name}
              name={item.name}
              label={item.label ? item.label : ""}
              type={item.type}
              fullWidth
              variant="outlined"
              value={item.type === "date" ? "23-04-2024" : ""}
            // value={ item.name === data+`.`+`${item.name}` ? data}
            // onChange={(e) => setName(e.target.value)}
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModal({ ...modal, isOpen: false })}>Cancel</Button>
        <Button type="submit">Apply</Button>
      </DialogActions>
    </Dialog>

  </>
  );
}

FeedbackEmpTable.propTypes = {
  handleDialogueOpen: PropTypes.func,
};

export const SIGNUP_FORM_DATA = {
  PHONE_NUMBER: {
    label: 'Phone Number *',
    placeholder: 'Enter Phone Number',
  },
  FULL_NAME: {
    label: 'Full Name *',
    placeholder: 'Enter Full Name',
  },
  EMAIL: {
    label: 'Email *',
    placeholder: 'Enter Email',
  },
  DATE: {
    label: 'Date of Birth *',
    placeholder: 'Enter Date of Birth',
  },

  GENDER: {
    OPTION: [
      {
        label: 'Male',
        value: 'male',
      },
      {
        label: 'Female',
        value: 'female',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  },
};

import React, { useEffect, useState } from "react";
import { imgUrl } from "../SalonStaff/Admin/AdminLogin";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  keyframes,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { services_customer } from "./constant";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { ALL_SALON_BY_PIN_CODE } from "../../api/salon-api/salon-service";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  ISalonSearchContent,
  ISalonSearchData,
} from "../SalonStaff/Admin/SystemManagement/ConfigureServices/types/configService";
import { setLogin } from "../../redux/feature/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const SalonPick = () => {
  const [inputVal, setInputVal] = useState("");
  const [searchList, setSearchList] = useState<ISalonSearchData | null>(null);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(searchList, "serach_list");

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputVal(e.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    const payload = {
      pincode: inputVal,
    };

    try {
      const response = await axiosPrivate({
        method: "POST",
        url: ALL_SALON_BY_PIN_CODE,
        data: payload,
      });

      setSearchList(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSalonClick = (data: ISalonSearchContent) => {
    const body = {
      token: "",
      userRole: "SALON",
      salonId: data.salonId,
      clientId: "",
    };
    dispatch(setLogin({ ...body, ...data }));
    localStorage.setItem("salon-info", JSON.stringify({ ...body, ...data }));
    navigate("/customer/signup");
  };

  useEffect(() => {
    // auto login
    if (localStorage.getItem("salon-info")) {
      const data = JSON.parse(localStorage.getItem("salon-info") || "{}");
      dispatch(setLogin(data));
      navigate("/customer/signup");
    }
  }, []);

  return (
    <div
      className="bg-slate-400 min-h-screen flex p-10 justify-center flex-col"
      style={{}}
    >
      <div className="flex flex-col items-center gap-9">
        <div>
          <Typography variant="h3" textAlign={"center"}>
            Pickup your nearest salon
          </Typography>
        </div>

        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <TextField
            size="small"
            label="Pickup your nearest salon"
            placeholder="Enter your zipcode"
            sx={{ width: "max-content" }}
            onChange={onChangeHandler}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={handleSearch}
          >
            Search
          </LoadingButton>
        </Stack>
        <Stack
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.default",
            borderRadius: 1,
          }}
        >
          {" "}
          {searchList && (
            <List>
              {searchList.content.length > 0 &&
                searchList.content.map((element) => {
                  return (
                    <ListItem disablePadding>
                      <ListItemButton onClick={() => handleSalonClick(element)}>
                        <ListItemText primary={element.salonName} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              {searchList.content.length === 0 && (
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  No Salon Found
                </Typography>
              )}
            </List>
          )}
        </Stack>
      </div>
      <Stack>
        <Typography variant="h6" gutterBottom>
          Our Most Popular Services <Divider sx={{ borderColor: "black" }} />
        </Typography>
        <Stack direction={"row"} flexWrap={"wrap"} justifyContent={"center"}>
          {services_customer.map((service) => (
            <Card
              sx={{
                maxWidth: 250,
                m: 2,
                transition:
                  "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                // animation: `${slowPulse} 10s infinite`, // Applying the infinite pulse animation
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                },
              }}
              key={service.name}
            >
              <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={service.imgUrl}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {service.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Stack>
    </div>
  );
};

export default SalonPick;

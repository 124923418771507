import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import {
  FieldInputProps,
  FormikErrors,
  FormikProps,
  FormikValues,
} from "formik";

type List = {
  label: string;
};

interface IProps {
  field: FieldInputProps<FormikErrors<FormikValues>>;
  form: FormikProps<FormikValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sx?: any;
  list: ReadonlyArray<List>;
  handleSelect: (
    event: React.SyntheticEvent<Element, Event>,
    value: List | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<List> | undefined
  ) => void;
}

/**
 * Renders an AutoCompleteFormik component.
 *
 * @param {IProps} props - The props for the component.
 * @param {FieldInputProps<FormikErrors<FormikValues>>} props.field - The field input props.
 * @param {FormikProps<FormikValues>} props.form - The form props.
 * @param {any} props.sx - The sx prop.
 * @param {ReadonlyArray<List>} props.list - The list of options.
 * @param {(event: React.SyntheticEvent<Element, Event>, value: List | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<List> | undefined) => void} props.handleSelect - The handleSelect function.
 * @return {JSX.Element} The rendered AutoCompleteFormik component.
 */
export default function AutoCompleteFormik({
  field,
  form,
  sx,
  list,
  handleSelect,
  ...props
}: IProps) {
  const { errors, values } = form;
  const { name } = field;
  const value = values[name];

  //   const hasError = Boolean(errors[field.name] && touched[field.name]); // Check for both error and touched state
  const errorMessage = errors[field.name] as string;

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={list}
      value={value}
      sx={{ width: "100%" }}
      onChange={handleSelect}
      renderInput={(params) => {
        console.log({ params, props, field }, "this_is_params");
        const { InputProps } = params;
        const { ref } = InputProps;
        return (
          <TextField
            sx={{ ...sx }}
            {...params}
            {...field}
            {...props}
            ref={ref}
            error={Boolean(form.touched[field.name] && form.errors[field.name])}
            helperText={errorMessage}
          />
        );
      }}
    />
  );
}

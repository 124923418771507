export const headCellsProducts = [
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "avgUsageQuantity",
    numeric: false,
    disablePadding: false,
    label: "Usage Quantity",
  },
  {
    id: "selectedVendor",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: false,
    label: "Brand",
  },
  {
    id: "operations",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

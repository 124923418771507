// react
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// MUI
import {
  FormControl,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from '@mui/material';

// components
import { BaseCard } from '../../../../../components/Mui';
import { EMP_FEEDBACK_DATA, empRatings } from './EmpConstant';
import { CustomizedDialog } from '../../../../../components/Mui/feedback/Dialog';

import { useSelector, useDispatch } from 'react-redux';

// icons
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { FeedbackEmpTable } from './FeedbackTable';

export default function EmployeeFeedback() {
  /**
   * Todo: API integration pending here 23/04/2024
   *  1. By Default 1st employee will be selected
   *  2. User Can select employee from dropdown
   * !Question - Current Redux state not getting required data like emp ratings id etc.
   */
  const [openDialogue, setOpenDialogue] = useState(false);
  const [rowData, setRowData] = useState({})

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };
  const handleDialogueOpen = (id, row) => {
    // this employee will be selected
    setRowData(row)
    setOpenDialogue(true);
  };

  return (
    <BaseCard sx={{ p: 0 }}>
      <FeedbackEmpTable handleDialogueOpen={handleDialogueOpen} />

      <CustomizedDialog
        openDialog={openDialogue}
        handleCloseDialog={handleDialogueClose}
        title={'Employee Feedback'}
        // disableClose={buttonLoader}
        icon={<SupervisedUserCircleIcon />}
        maxWidth='sm'
        isActionButtonRequired={false}
      >
        {/* EmpSelector */}
        {/* <EmpSelect /> */}
        {/* Ratings */}
        <div className=' flex items-center w-full max-w-[450px] min-w-72 justify-center  mx-auto flex-col md:flex-row '>
          <Stack direction={'column'}>
            <Ratings label="Customer Satisfaction" value={rowData.customerSatisfaction} />
            <Ratings label="Quality Of Service" value={rowData.qualityOfService} />
            <Ratings label="Staff Behaviour" value={rowData.staffBehaviour} />
            <Ratings label="Time Accuracy" value={rowData.timeAccuracy} />
          </Stack>
          <OverAllRating label={'Over All Rating'} value={rowData.rating} />
        </div>
      </CustomizedDialog>
    </BaseCard>
  );
}

/**
 * This function JSX may be use in future
 * Renders a component for selecting an employee from a list.
 *
 * @return {JSX.Element} The JSX element for the employee selection component.
 * @deprecated use {@link EmpSelect}
 */
const EmpSelect = () => {
  const allEmployees = useSelector((state) => state.Employee.allEmployees) || [];

  const [employee, setEmployee] = useState('');

  /**
   * Updates the selected employee based on the selected value from the event target.
   *
   * @param {Event} event - The event object containing the target value.
   * @return {void} This function does not return anything.
   */
  const handleChange = (event) => {
    setEmployee(event.target.value);
  };
  return (
    <Stack>
      <FormControl sx={{ m: 1, width: 300 }} size='small'>
        <InputLabel id='employee-feedback'>{EMP_FEEDBACK_DATA.LABEL}</InputLabel>

        <Select value={employee} label={EMP_FEEDBACK_DATA.LABEL} onChange={handleChange}>
          {allEmployees.map((item) => (
            <MenuItem key={item.empId} value={item?.empId}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};



const Ratings = ({ label = '', value }) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} m={0.5}>
      <Stack>
        <Typography component='body2' mr={1}>
          {label}
        </Typography>
      </Stack>
      <Rating readOnly name={label} value={value} precision={0.5} />
    </Stack>
  );
};

Ratings.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

const OverAllRating = ({ label = '', value }) => {
  return (
    <Stack>
      <Typography component='h3' sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {EMP_FEEDBACK_DATA.OVER_ALL_RATING}
      </Typography>
      <div className='m-1 rounded-full border h-32 w-32 flex justify-center items-center flex-col'>
        <Stack>
          <Typography component='body2' sx={{ textAlign: 'center' }}>
            {EMP_FEEDBACK_DATA.OVER_ALL}
          </Typography>
        </Stack>
        <Typography>{value}</Typography>
        <Rating name={label} value={value} readOnly size={'small'} precision={0.5} />
      </div>
    </Stack>
  );
};

OverAllRating.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
};

import { IVendorEntity } from "../../../SystemManagement/ConfigureServices/types/configService";
import { IOpenVendorForm } from "../VendorTable";

export const VENDOR_FIELD_NAMES = {
  name: "name",
  email: "email",
  address: "address",
  city: "city",
  state: "state",
  status: "status",
};

export const vendorInitialValue = (formInfo: IOpenVendorForm) => {
  const formData = formInfo.mode === "EDIT" ? formInfo.data : null;
  return {
    name: formData?.name || "",
    email: formData?.email || "",
    address: formData?.address || "",
    city: formData?.city || "",
    state: formData?.state || "",
    status: formData?.status || "ACTIVE",
  } as IVendorEntity;
};

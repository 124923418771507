export const SERVICE_DATA_KEY = {
  ID: "id",
  SERVICE_NAME: "serviceName",
  SERVICE_TIME: "avgServeTime",
  SERVICE_GENDER: "gender",
  COST: "price",
  STATUS : "status",
  OPERATIONS : "",

};


export const headCellsServiceConfig = [
  {
    id: SERVICE_DATA_KEY.SERVICE_NAME,
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: SERVICE_DATA_KEY.SERVICE_NAME,
    numeric: false,
    disablePadding: false,
    label: "Services",
  },
  {
    id: SERVICE_DATA_KEY.SERVICE_TIME,
    numeric: false,
    disablePadding: false,
    label: "Service Time",
  },
  {
    id: SERVICE_DATA_KEY.SERVICE_GENDER,
    numeric: false,
    disablePadding: false,
    label: "Staff Gender",
  },
  {
    id: SERVICE_DATA_KEY.COST,
    numeric: false,
    disablePadding: false,
    label: "Service Cost",
  },
  {
    id: SERVICE_DATA_KEY.STATUS,
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: SERVICE_DATA_KEY.OPERATIONS,
    numeric: true,
    disablePadding: false,
    label: "",
  },
];


export const dummyConfigData = {
  totalElements: 0,
  totalPages: 0,
  page: 0,
  content: [
    {
      serviceName: "string",
      avgServeTime: 0,
      status: "ACTIVE",
      gender: "string",
      serviceCategory: "string",
      description: "string",
      price: 0,
      products: [
        {
          id: 0,
          createdTime: "2024-05-02T07:42:38.494Z",
          modifiedDate: "2024-05-02T07:42:38.494Z",
          name: "string",
          description: "string",
          brandName: "string",
          status: "ACTIVE",
          selectedVendor: "string",
          productCategory: {
            id: 0,
            createdTime: "2024-05-02T07:42:38.494Z",
            modifiedDate: "2024-05-02T07:42:38.494Z",
            categoryName: "string",
            desc: "string",
            usageQuantity: 0,
            status: "ACTIVE",
            products: ["string"],
          },
          vendorEntities: [
            {
              id: 0,
              createdTime: "2024-05-02T07:42:38.494Z",
              modifiedDate: "2024-05-02T07:42:38.494Z",
              name: "string",
              email: "string",
              address: "string",
              city: "string",
              state: "string",
              status: "ACTIVE",
              productEntityList: ["string"],
            },
          ],
          salonService: "string",
        },
      ],
      serviceCategoryEntity: {
        id: 0,
        createdTime: "2024-05-02T07:42:38.494Z",
        modifiedDate: "2024-05-02T07:42:38.494Z",
        categoryName: "string",
        description: "string",
        status: "ACTIVE",
        discountConfig: {
          id: 0,
          createdTime: "2024-05-02T07:42:38.494Z",
          modifiedDate: "2024-05-02T07:42:38.494Z",
          offerName: "string",
          discountType: "FLAT",
          minimumOrderAmount: 0,
          salonId: "string",
          maxDiscountAmount: 0,
          discountAmount: 0,
          serviceCategories: ["string"],
          offerStartDate: "2024-05-02T07:42:38.494Z",
          offerEndDate: "2024-05-02T07:42:38.494Z",
          status: "ACTIVE",
        },
      },
      salonEntity: {
        id: 0,
        createdTime: "2024-05-02T07:42:38.494Z",
        modifiedDate: "2024-05-02T07:42:38.494Z",
        regNo: "string",
        adminId: "string",
        salonOpeningTime: {
          hour: 0,
          minute: 0,
          second: 0,
          nano: 0,
        },
        salonClosingTime: {
          hour: 0,
          minute: 0,
          second: 0,
          nano: 0,
        },
        name: "string",
        address: "string",
        salonManagerName: "string",
        salonManagerPhoneNumber: 0,
        salonManagerEmail: "string",
        salonZonalManagerName: "string",
        salonZonalManagerEmail: "string",
        salonZonalManagerPhoneNumber: 0,
        salonTypeStatus: "MALE",
        state: "string",
        city: "string",
        postalCode: 0,
        ownerName: "string",
        logoS3Path: "string",
        status: "ACTIVE",
        multipleOutletInd: true,
        franchiseEntity: {
          id: 0,
          createdTime: "2024-05-02T07:42:38.494Z",
          modifiedDate: "2024-05-02T07:42:38.494Z",
          address: "string",
          ownerName: "string",
          ownerEmail: "string",
          franchiseOutlet: true,
          regNo: "string",
          logoS3Path: "string",
          name: "string",
          status: "ACTIVE",
          salonEntity: "string",
        },
        salonServices: [
          {
            id: 0,
            createdTime: "2024-05-02T07:42:38.494Z",
            modifiedDate: "2024-05-02T07:42:38.494Z",
            serviceName: "string",
            avgServeTime: 0,
            status: "ACTIVE",
            gender: "string",
            description: "string",
            price: 0,
            serviceCategoryEntity: {
              id: 0,
              createdTime: "2024-05-02T07:42:38.494Z",
              modifiedDate: "2024-05-02T07:42:38.494Z",
              categoryName: "string",
              description: "string",
              status: "ACTIVE",
              discountConfig: {
                id: 0,
                createdTime: "2024-05-02T07:42:38.494Z",
                modifiedDate: "2024-05-02T07:42:38.494Z",
                offerName: "string",
                discountType: "FLAT",
                minimumOrderAmount: 0,
                salonId: "string",
                maxDiscountAmount: 0,
                discountAmount: 0,
                serviceCategories: ["string"],
                offerStartDate: "2024-05-02T07:42:38.494Z",
                offerEndDate: "2024-05-02T07:42:38.494Z",
                status: "ACTIVE",
              },
            },
            salonEntity: "string",
            salonStaff: ["string"],
            products: [
              {
                id: 0,
                createdTime: "2024-05-02T07:42:38.494Z",
                modifiedDate: "2024-05-02T07:42:38.494Z",
                name: "string",
                description: "string",
                brandName: "string",
                status: "ACTIVE",
                selectedVendor: "string",
                productCategory: {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.494Z",
                  modifiedDate: "2024-05-02T07:42:38.494Z",
                  categoryName: "string",
                  desc: "string",
                  usageQuantity: 0,
                  status: "ACTIVE",
                  products: ["string"],
                },
                vendorEntities: [
                  {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.494Z",
                    modifiedDate: "2024-05-02T07:42:38.494Z",
                    name: "string",
                    email: "string",
                    address: "string",
                    city: "string",
                    state: "string",
                    status: "ACTIVE",
                    productEntityList: ["string"],
                  },
                ],
                salonService: "string",
              },
            ],
          },
        ],
        salonStaff: ["string"],
        franchise: [
          {
            id: 0,
            createdTime: "2024-05-02T07:42:38.494Z",
            modifiedDate: "2024-05-02T07:42:38.494Z",
            address: "string",
            ownerName: "string",
            ownerEmail: "string",
            franchiseOutlet: true,
            regNo: "string",
            logoS3Path: "string",
            name: "string",
            status: "ACTIVE",
            salonEntity: "string",
          },
        ],
      },
      salonServices: [
        {
          id: 0,
          createdTime: "2024-05-02T07:42:38.494Z",
          modifiedDate: "2024-05-02T07:42:38.494Z",
          name: "string",
          status: "ACTIVE",
          gender: "string",
          overAllRating: 0,
          address: "string",
          isAvailable: true,
          mobileNo: 0,
          checkedIn: true,
          salonEntity: {
            id: 0,
            createdTime: "2024-05-02T07:42:38.494Z",
            modifiedDate: "2024-05-02T07:42:38.494Z",
            regNo: "string",
            adminId: "string",
            salonOpeningTime: {
              hour: 0,
              minute: 0,
              second: 0,
              nano: 0,
            },
            salonClosingTime: {
              hour: 0,
              minute: 0,
              second: 0,
              nano: 0,
            },
            name: "string",
            address: "string",
            salonManagerName: "string",
            salonManagerPhoneNumber: 0,
            salonManagerEmail: "string",
            salonZonalManagerName: "string",
            salonZonalManagerEmail: "string",
            salonZonalManagerPhoneNumber: 0,
            salonTypeStatus: "MALE",
            state: "string",
            city: "string",
            postalCode: 0,
            ownerName: "string",
            logoS3Path: "string",
            status: "ACTIVE",
            multipleOutletInd: true,
            franchiseEntity: {
              id: 0,
              createdTime: "2024-05-02T07:42:38.494Z",
              modifiedDate: "2024-05-02T07:42:38.494Z",
              address: "string",
              ownerName: "string",
              ownerEmail: "string",
              franchiseOutlet: true,
              regNo: "string",
              logoS3Path: "string",
              name: "string",
              status: "ACTIVE",
              salonEntity: "string",
            },
            salonServices: [
              {
                id: 0,
                createdTime: "2024-05-02T07:42:38.494Z",
                modifiedDate: "2024-05-02T07:42:38.494Z",
                serviceName: "string",
                avgServeTime: 0,
                status: "ACTIVE",
                gender: "string",
                description: "string",
                price: 0,
                serviceCategoryEntity: {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.494Z",
                  modifiedDate: "2024-05-02T07:42:38.494Z",
                  categoryName: "string",
                  description: "string",
                  status: "ACTIVE",
                  discountConfig: {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.494Z",
                    modifiedDate: "2024-05-02T07:42:38.494Z",
                    offerName: "string",
                    discountType: "FLAT",
                    minimumOrderAmount: 0,
                    salonId: "string",
                    maxDiscountAmount: 0,
                    discountAmount: 0,
                    serviceCategories: ["string"],
                    offerStartDate: "2024-05-02T07:42:38.494Z",
                    offerEndDate: "2024-05-02T07:42:38.494Z",
                    status: "ACTIVE",
                  },
                },
                salonEntity: "string",
                salonStaff: ["string"],
                products: [
                  {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.494Z",
                    modifiedDate: "2024-05-02T07:42:38.494Z",
                    name: "string",
                    description: "string",
                    brandName: "string",
                    status: "ACTIVE",
                    selectedVendor: "string",
                    productCategory: {
                      id: 0,
                      createdTime: "2024-05-02T07:42:38.494Z",
                      modifiedDate: "2024-05-02T07:42:38.494Z",
                      categoryName: "string",
                      desc: "string",
                      usageQuantity: 0,
                      status: "ACTIVE",
                      products: ["string"],
                    },
                    vendorEntities: [
                      {
                        id: 0,
                        createdTime: "2024-05-02T07:42:38.494Z",
                        modifiedDate: "2024-05-02T07:42:38.494Z",
                        name: "string",
                        email: "string",
                        address: "string",
                        city: "string",
                        state: "string",
                        status: "ACTIVE",
                        productEntityList: ["string"],
                      },
                    ],
                    salonService: "string",
                  },
                ],
              },
            ],
            salonStaff: ["string"],
            franchise: [
              {
                id: 0,
                createdTime: "2024-05-02T07:42:38.494Z",
                modifiedDate: "2024-05-02T07:42:38.494Z",
                address: "string",
                ownerName: "string",
                ownerEmail: "string",
                franchiseOutlet: true,
                regNo: "string",
                logoS3Path: "string",
                name: "string",
                status: "ACTIVE",
                salonEntity: "string",
              },
            ],
          },
          salonServices: [
            {
              id: 0,
              createdTime: "2024-05-02T07:42:38.494Z",
              modifiedDate: "2024-05-02T07:42:38.494Z",
              serviceName: "string",
              avgServeTime: 0,
              status: "ACTIVE",
              gender: "string",
              description: "string",
              price: 0,
              serviceCategoryEntity: {
                id: 0,
                createdTime: "2024-05-02T07:42:38.494Z",
                modifiedDate: "2024-05-02T07:42:38.494Z",
                categoryName: "string",
                description: "string",
                status: "ACTIVE",
                discountConfig: {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.495Z",
                  modifiedDate: "2024-05-02T07:42:38.495Z",
                  offerName: "string",
                  discountType: "FLAT",
                  minimumOrderAmount: 0,
                  salonId: "string",
                  maxDiscountAmount: 0,
                  discountAmount: 0,
                  serviceCategories: ["string"],
                  offerStartDate: "2024-05-02T07:42:38.495Z",
                  offerEndDate: "2024-05-02T07:42:38.495Z",
                  status: "ACTIVE",
                },
              },
              salonEntity: "string",
              salonStaff: ["string"],
              products: [
                {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.495Z",
                  modifiedDate: "2024-05-02T07:42:38.495Z",
                  name: "string",
                  description: "string",
                  brandName: "string",
                  status: "ACTIVE",
                  selectedVendor: "string",
                  productCategory: {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.495Z",
                    modifiedDate: "2024-05-02T07:42:38.495Z",
                    categoryName: "string",
                    desc: "string",
                    usageQuantity: 0,
                    status: "ACTIVE",
                    products: ["string"],
                  },
                  vendorEntities: [
                    {
                      id: 0,
                      createdTime: "2024-05-02T07:42:38.495Z",
                      modifiedDate: "2024-05-02T07:42:38.495Z",
                      name: "string",
                      email: "string",
                      address: "string",
                      city: "string",
                      state: "string",
                      status: "ACTIVE",
                      productEntityList: ["string"],
                    },
                  ],
                  salonService: "string",
                },
              ],
            },
          ],
        },
      ],
      salonStaffList: [
        {
          id: 0,
          createdTime: "2024-05-02T07:42:38.495Z",
          modifiedDate: "2024-05-02T07:42:38.495Z",
          name: "string",
          status: "ACTIVE",
          gender: "string",
          overAllRating: 0,
          address: "string",
          isAvailable: true,
          mobileNo: 0,
          checkedIn: true,
          salonEntity: {
            id: 0,
            createdTime: "2024-05-02T07:42:38.495Z",
            modifiedDate: "2024-05-02T07:42:38.495Z",
            regNo: "string",
            adminId: "string",
            salonOpeningTime: {
              hour: 0,
              minute: 0,
              second: 0,
              nano: 0,
            },
            salonClosingTime: {
              hour: 0,
              minute: 0,
              second: 0,
              nano: 0,
            },
            name: "string",
            address: "string",
            salonManagerName: "string",
            salonManagerPhoneNumber: 0,
            salonManagerEmail: "string",
            salonZonalManagerName: "string",
            salonZonalManagerEmail: "string",
            salonZonalManagerPhoneNumber: 0,
            salonTypeStatus: "MALE",
            state: "string",
            city: "string",
            postalCode: 0,
            ownerName: "string",
            logoS3Path: "string",
            status: "ACTIVE",
            multipleOutletInd: true,
            franchiseEntity: {
              id: 0,
              createdTime: "2024-05-02T07:42:38.495Z",
              modifiedDate: "2024-05-02T07:42:38.495Z",
              address: "string",
              ownerName: "string",
              ownerEmail: "string",
              franchiseOutlet: true,
              regNo: "string",
              logoS3Path: "string",
              name: "string",
              status: "ACTIVE",
              salonEntity: "string",
            },
            salonServices: [
              {
                id: 0,
                createdTime: "2024-05-02T07:42:38.495Z",
                modifiedDate: "2024-05-02T07:42:38.495Z",
                serviceName: "string",
                avgServeTime: 0,
                status: "ACTIVE",
                gender: "string",
                description: "string",
                price: 0,
                serviceCategoryEntity: {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.495Z",
                  modifiedDate: "2024-05-02T07:42:38.495Z",
                  categoryName: "string",
                  description: "string",
                  status: "ACTIVE",
                  discountConfig: {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.495Z",
                    modifiedDate: "2024-05-02T07:42:38.495Z",
                    offerName: "string",
                    discountType: "FLAT",
                    minimumOrderAmount: 0,
                    salonId: "string",
                    maxDiscountAmount: 0,
                    discountAmount: 0,
                    serviceCategories: ["string"],
                    offerStartDate: "2024-05-02T07:42:38.495Z",
                    offerEndDate: "2024-05-02T07:42:38.495Z",
                    status: "ACTIVE",
                  },
                },
                salonEntity: "string",
                salonStaff: ["string"],
                products: [
                  {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.495Z",
                    modifiedDate: "2024-05-02T07:42:38.495Z",
                    name: "string",
                    description: "string",
                    brandName: "string",
                    status: "ACTIVE",
                    selectedVendor: "string",
                    productCategory: {
                      id: 0,
                      createdTime: "2024-05-02T07:42:38.495Z",
                      modifiedDate: "2024-05-02T07:42:38.495Z",
                      categoryName: "string",
                      desc: "string",
                      usageQuantity: 0,
                      status: "ACTIVE",
                      products: ["string"],
                    },
                    vendorEntities: [
                      {
                        id: 0,
                        createdTime: "2024-05-02T07:42:38.495Z",
                        modifiedDate: "2024-05-02T07:42:38.495Z",
                        name: "string",
                        email: "string",
                        address: "string",
                        city: "string",
                        state: "string",
                        status: "ACTIVE",
                        productEntityList: ["string"],
                      },
                    ],
                    salonService: "string",
                  },
                ],
              },
            ],
            salonStaff: ["string"],
            franchise: [
              {
                id: 0,
                createdTime: "2024-05-02T07:42:38.495Z",
                modifiedDate: "2024-05-02T07:42:38.495Z",
                address: "string",
                ownerName: "string",
                ownerEmail: "string",
                franchiseOutlet: true,
                regNo: "string",
                logoS3Path: "string",
                name: "string",
                status: "ACTIVE",
                salonEntity: "string",
              },
            ],
          },
          salonServices: [
            {
              id: 0,
              createdTime: "2024-05-02T07:42:38.495Z",
              modifiedDate: "2024-05-02T07:42:38.495Z",
              serviceName: "string",
              avgServeTime: 0,
              status: "ACTIVE",
              gender: "string",
              description: "string",
              price: 0,
              serviceCategoryEntity: {
                id: 0,
                createdTime: "2024-05-02T07:42:38.495Z",
                modifiedDate: "2024-05-02T07:42:38.495Z",
                categoryName: "string",
                description: "string",
                status: "ACTIVE",
                discountConfig: {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.495Z",
                  modifiedDate: "2024-05-02T07:42:38.495Z",
                  offerName: "string",
                  discountType: "FLAT",
                  minimumOrderAmount: 0,
                  salonId: "string",
                  maxDiscountAmount: 0,
                  discountAmount: 0,
                  serviceCategories: ["string"],
                  offerStartDate: "2024-05-02T07:42:38.495Z",
                  offerEndDate: "2024-05-02T07:42:38.495Z",
                  status: "ACTIVE",
                },
              },
              salonEntity: "string",
              salonStaff: ["string"],
              products: [
                {
                  id: 0,
                  createdTime: "2024-05-02T07:42:38.495Z",
                  modifiedDate: "2024-05-02T07:42:38.495Z",
                  name: "string",
                  description: "string",
                  brandName: "string",
                  status: "ACTIVE",
                  selectedVendor: "string",
                  productCategory: {
                    id: 0,
                    createdTime: "2024-05-02T07:42:38.495Z",
                    modifiedDate: "2024-05-02T07:42:38.495Z",
                    categoryName: "string",
                    desc: "string",
                    usageQuantity: 0,
                    status: "ACTIVE",
                    products: ["string"],
                  },
                  vendorEntities: [
                    {
                      id: 0,
                      createdTime: "2024-05-02T07:42:38.495Z",
                      modifiedDate: "2024-05-02T07:42:38.495Z",
                      name: "string",
                      email: "string",
                      address: "string",
                      city: "string",
                      state: "string",
                      status: "ACTIVE",
                      productEntityList: ["string"],
                    },
                  ],
                  salonService: "string",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

import { createSlice } from "@reduxjs/toolkit";
import { IInitialInventoryService } from "./slice";

const initialState: IInitialInventoryService = {
  allProducts: null,
  allInventory: null,
};

export const inventoryService = createSlice({
  name: "inventoryService",
  initialState: initialState,
  reducers: {
    setAllProducts: (state: IInitialInventoryService, action) => {
      state.allProducts = action.payload;
    },

    setAddProduct: (state: IInitialInventoryService, action) => {
      if (state.allProducts === null) return;
      state.allProducts.content = [
        action.payload,
        ...state.allProducts.content,
      ];
    },

    setUpdateProduct: (state: IInitialInventoryService, action) => {
      if (state.allProducts === null) return;
      state.allProducts.content = state.allProducts.content.map((product) => {
        if (product.id === action.payload.id) {
          return action.payload;
        }
        return product;
      });
    },

    setDeleteProduct: (state: IInitialInventoryService, action) => {
      if (state.allProducts === null) return;
      state.allProducts.content = state.allProducts.content.map((product) => {
        if (product.id === action.payload) {
          return {
            ...product,
            status: "DELETED",
          };
        }
        return {
          ...product,
        };
      });
    },

    // Inventory Service
    setAllInventoryService: (state: IInitialInventoryService, action) => {
      state.allInventory = action.payload;
    },
    setAddInventoryService: (state: IInitialInventoryService, action) => {
      if (state.allInventory === null) return;
      state.allInventory.content = [
        action.payload,
        ...state.allInventory.content,
      ];
    },
    setUpdateInventoryService: (state: IInitialInventoryService, action) => {
      if (state.allInventory === null) return;
      state.allInventory.content = state.allInventory.content.map(
        (inventory) => {
          if (inventory.id === action.payload.id) {
            return action.payload;
          }
          return inventory;
        }
      );
    },
    setDeleteInventoryService: (state: IInitialInventoryService, action) => {
      if (state.allInventory === null) return;
      state.allInventory.content = state.allInventory.content.map(
        (inventory) => {
          if (inventory.id === action.payload) {
            return {
              ...inventory,
              status: "DELETED",
            };
          }
          return {
            ...inventory,
          };
        }
      );
    },
  },
});

export const {
  setAllProducts,
  setAddProduct,
  setUpdateProduct,
  setDeleteProduct,

  // Inventory Service
  setAllInventoryService,
  setUpdateInventoryService,
  setAddInventoryService,
  setDeleteInventoryService,
} = inventoryService.actions;
export default inventoryService.reducer;

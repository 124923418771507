import axios from "axios";
import { BASE_URL } from "../../constants/baseURL";
const CUSTOMER = "v1/customers";
export const ALL_CUSTOMERS = "v1/customers/all"; // Assuming consistent forward slash usage

export async function getAllCustomers({ page = 1, size = 1000 }) {
  const url = `${BASE_URL.CUSTOMER_SERVICE_URL}${ALL_CUSTOMERS}`; // Template literals for cleaner string concatenation

  const headers = {
    "Content-Type": "application/json", // Consistent capitalization
  };

  try {
    const response = await axios.get(url, {
      headers,
      params: { page, size }, // Use 'params' for query parameters
    });

    return response.data; // Assuming the data is in the 'data' property
  } catch (error) {
    // Handle errors gracefully, e.g., log the error, throw a specific error
    console.error("Error fetching customers:", error);
    throw new Error("Failed to get all customers"); // Or a more informative error message
  }
}

export async function registerCustomer(payload) {
  const url = BASE_URL.CUSTOMER_SERVICE_URL + CUSTOMER;
  let header = {
    "Content-type": "application/json",
  };
  try {
    const response = await axios({
      method: "post",
      headers: header,
      url: url,
      data: payload,
    });
    return {
      token: response.data,
      statusCode: response.status,
      isCustomer: true,
    };
  } catch (error) {
    // Handle errors gracefully, e.g., log the error, throw a specific error
    console.error("Error creating customers:", error);
    throw new Error("Failed to get create customer"); // Or a more informative error message
  }
}

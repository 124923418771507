import React from "react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Link as RouterLinks, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDrawer } from "../../redux/feature/commonSlice";
import { Popover } from "@mui/material";

const lightColor = "rgba(255, 255, 255, 0.7)";

function Header({ onDrawerToggle, title = "", subTabs = [] }) {
  const [value, setValue] = React.useState(0);
  const [openPopover, setOpenPopover] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UploadLogo = useSelector((state) => state.UploadLogo?.logo)


  const handleChange = (newValue) => {
    setValue(newValue);
  };


  function handleOpenPopover(event){
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  }

  function handleClosePopover(){
    setAnchorEl(null);
    setOpenPopover(false);
  }

  const handleOpenDrawer = () => {
    dispatch(setOpenDrawer());
  };

  const { isDrawerOpen } = useSelector((state) => state.common);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate("/");
  };

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <React.Fragment>
        <AppBar color="primary" position="sticky" elevation={0}>
          <Toolbar>
            <Grid container spacing={1} alignItems="center">
              <Grid
                // sx={{ display: { md: "none", sm: "block", xs: "block" }  }}
                sx={{
                  width: {
                    sm: !isDrawerOpen ? "18rem" : "2rem",
                    md: "2rem",
                    xs: !isDrawerOpen ? "18rem" : "2rem",
                  },
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                item
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => handleOpenDrawer()}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Button
                  onClick={handleLogout}
                  variant="body2"
                  sx={{
                    textDecoration: "none",
                    color: lightColor,
                    "&:hover": {
                      color: "common.white",
                    },
                  }}
                  rel="noopener noreferrer"
                >
                  Logout
                </Button>
              </Grid>
              {/* <Grid item>
                <Tooltip title="Alerts • No alerts">
                  <IconButton color="inherit">
                    <NotificationsIcon />
                  </IconButton>
                </Tooltip>
              </Grid> */}
              <Grid item>
                <IconButton color="inherit" sx={{ p: 0.5 }} variant="contained" onClick={handleOpenPopover}>
                  <Avatar src={UploadLogo} alt="My Avatar" />
                </IconButton>
              </Grid>
            </Grid>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Grid container direction={"column"} gap={1} marginY={1}>
                  <Grid item bgcolor={"#dadada"} width={"100%"} paddingX={2} textAlign={"center"}>
                      <Typography variant="h6">
                      Notifications
                      </Typography>
                  </Grid>
                  <Grid item width={"100%"} paddingX={2}>
                  <Typography>
                    No Notifications currently
                  </Typography>
                  </Grid>
                  
              </Grid>
            </Popover>
          </Toolbar>
        </AppBar>
        <AppBar
          component="div"
          color="primary"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Typography color="inherit" variant="h5" component="h1">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  sx={{ borderColor: lightColor }}
                  variant="outlined"
                  color="inherit"
                  size="small"
                >
                  Software Updates
                </Button>
              </Grid>
              {/* <Grid item>
                <Tooltip title="Help">
                  <IconButton color="inherit">
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              </Grid> */}
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Tabs
            value={value}
            textColor="inherit"
            selectionFollowsFocus
            onChange={handleChange}
          >
            {subTabs.length > 0 &&
              subTabs.map((item, index) => (
                <RouterLinks to={item.to} key={index}>
                  <Tab label={item.label} onClick={() => handleChange(index)} />
                </RouterLinks>
              ))}
          </Tabs>
        </AppBar>
      </React.Fragment>
      <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Header;

import { useDispatch, useSelector } from "react-redux";
import { RState } from "../../../redux/feature/slice";
import { useAxiosPrivateInventoryService } from "../../useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  setAddInventoryService,
  setAllInventoryService,
  setUpdateInventoryService,
} from "../../../redux/feature/inventoryService";
import { IAllInventory, InventoryContent } from "./inventoryService";
import { ALL_INVENTORY } from "../../../api/salon-api/inventory-service";

export const useAllSalonInventoryController = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const allInventory = useSelector(
    (state: RState) => state.inventoryService.allInventory
  );

  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [currentPage, setCurrentPage] = useState(
    allInventory?.page || initialPage
  );
  const [pageSize, setPageSize] = useState(
    allInventory?.totalPages || initialSize
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchAllInventory = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = !all
        ? {
            page: currentPage,
            size: pageSize,
          }
        : {};

      const response = await axiosPrivate.post<IAllInventory>(
        `${ALL_INVENTORY}/getAllInventory`,
        params
      );

      if (response.status === 200) {
        dispatch(setAllInventoryService(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      currentPage !== allInventory?.page ||
      pageSize !== allInventory?.totalPages
    ) {
      fetchAllInventory();
    } else {
      setIsLoading(false);
    }
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
    allInventory,
  };
};

export const useCreateInventory = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const salonId = useSelector((state: RState) => state.auth.data?.salonId);
  const createInventory = async (data: InventoryContent) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.post<InventoryContent>(
        ALL_INVENTORY,
        { ...data, salonId }
      );

      if (response.status === 200 || response.status === 201) {
        dispatch(setAddInventoryService(response.data));
      } else {
        setError(new Error("Failed to create product"));
      }
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error creating:", error);
      setError(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    createInventory,
  };
};

export const useUpdateInventory = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const salonId = useSelector((state: RState) => state.auth.data?.salonId);

  const updateInventory = async (data: InventoryContent) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.put<InventoryContent>(
        `${ALL_INVENTORY}/${data.id}`,
        { ...data, salonId }
      );

      if (response.status === 200) {
        dispatch(setUpdateInventoryService(response.data));
      } else {
        setError(new Error("Failed to update product"));
      }
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateInventory,
  };
};

export const useDeleteInventory = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivateInventoryService();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const salonId = useSelector((state: RState) => state.auth.data?.salonId);

  const deleteInventory = async (id: number) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.delete<InventoryContent>(
        `${ALL_INVENTORY}/${id}`
            );

      if (response.status === 200) {
        dispatch(setUpdateInventoryService(id));
      } else {
        setError(new Error("Failed to update product"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    deleteInventory,
  };
};

import * as Yup from "yup";
import { EMPLOYEE_CONFIG_FIELD_NAME } from "../constantEmpConfig";

// Form validation schema
export const validationEmpConfig = Yup.object().shape({
  [EMPLOYEE_CONFIG_FIELD_NAME.NAME]: Yup.string(
    "Name should be valid"
  ).required("Name is required"),
  [EMPLOYEE_CONFIG_FIELD_NAME.MOBILE_NO]: Yup.string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number should be 10 digits"),
  [EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS]: Yup.string().required(
    "Address is required"
  ),
  [EMPLOYEE_CONFIG_FIELD_NAME.GENDER]:
    Yup.string().required("Gender is required"),
  [EMPLOYEE_CONFIG_FIELD_NAME.SPECIALTY]: Yup.array()
    .required("Specialty is required")
    .typeError("Specialty is required"),
});

import { useEffect } from "react";
import { useAuth } from "./useAuth";
import {
  axiosPrivateAuthService,
  axiosPrivateFeedbackService,
  axiosPrivateInventoryService,
  axiosPrivateLogBookService,
} from "../api/axios";
import { AuthState } from "../redux/feature/authSlice";

const useAxiosPrivate = () => {
  const { auth }: AuthState = useAuth();
  // const state = useSelector((state) => state.);

  useEffect(() => {
    const requestIntercept = axiosPrivateAuthService.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${auth?.token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    return () => {
      axiosPrivateAuthService.interceptors.request.eject(requestIntercept);
    };
  }, []);

  return axiosPrivateAuthService;
};

export default useAxiosPrivate;

export const useAxiosPrivateFeedback = () => {
  const { auth }: AuthState = useAuth();

  useEffect(() => {
    const requestIntercept =
      axiosPrivateFeedbackService.interceptors.request.use(
        (config) => {
          if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${auth?.token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    return () => {
      axiosPrivateFeedbackService.interceptors.request.eject(requestIntercept);
    };
  }, [auth]);

  return axiosPrivateFeedbackService;
};

export const useAxiosPrivateLogBook = () => {
  const { auth }: AuthState = useAuth();

  useEffect(() => {
    const requestIntercept =
      axiosPrivateLogBookService.interceptors.request.use(
        (config) => {
          if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${auth?.token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    return () => {
      axiosPrivateLogBookService.interceptors.request.eject(requestIntercept);
    };
  }, [auth]);

  return axiosPrivateLogBookService;
};

export const useAxiosPrivateInventoryService = () => {
  const { auth }: AuthState = useAuth();

  useEffect(() => {
    const requestIntercept =
      axiosPrivateInventoryService.interceptors.request.use(
        (config) => {
          if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${auth?.token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    return () => {
      axiosPrivateInventoryService.interceptors.request.eject(requestIntercept);
    };
  }, [auth]);

  return axiosPrivateInventoryService;
};
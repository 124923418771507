import React from 'react'
import Header from '../../../../components/SalonStaffComponent/Header'

export default function SystemManagement() {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const subTabs = [
    {
      label : "Update Logo",
      to : "/adminDashboard/System-Management/",
    },
    {
      label : "Service Categories",
      to : "/adminDashboard/System-Management/serviceCategories",
    },
    {
      label : "Configure Services",
      to : "/adminDashboard/System-Management/configureServices",
    },
    {
      label : "Payment Configuration",
      to : "/adminDashboard/System-Management/paymentConfiguration",
    },
    {
      label : "Salon Configuration",
      to : "/adminDashboard/System-Management/salonConfiguration",
    },
    {
      label : "Tax Configuration",
      to : "/adminDashboard/System-Management/taxConfiguration",
    },
    // {
    //   label : "Printer Settings",
    //   to : "/adminDashboard/System-Management/printerSettings",
    // },
  ]

  return (
    <Header onDrawerToggle={handleDrawerToggle} title='System Management' subTabs={subTabs}/>
  )
}


import React from "react";
import InventoryTable from "./InventoryTable";
import { BaseCard } from "../../../../../components/Mui";

const SalonInventory = () => {
  return (
    <BaseCard>
      <InventoryTable />
    </BaseCard>
  );
};

export default SalonInventory;

import * as React from "react";
import { Stack, LinearProgress } from "@mui/material";
import { Field } from "formik";
import {
  MUI_SIZE,
  MUI_TEXT_FIELD_HIGHT,
  MUI_VARIANT,
} from "../../../../../../helper/Common/constant";
import { TextField } from "formik-mui";
import { EMPLOYEE_CONFIG_FIELD_NAME } from "../constantEmpConfig";

// icons
import BadgeIcon from "@mui/icons-material/Badge";
import KeyIcon from "@mui/icons-material/Key";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { FieldIcon } from "../../../../../../components/Mui/Formik/FieldIcon";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessIcon from "@mui/icons-material/Business";
import SelectField from "../../../../../../components/Mui/Formik/SelectField";
import { usePaginatedServices } from "../../../SystemManagement/ConfigureServices/configServiceApiHook";
import { useSelector } from "react-redux";
import MultiSelectFormik from "../../../../../../components/Mui/Formik/MultiSelectFormik";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ImageIcon from "@mui/icons-material/Image";
import { UploadImage } from "../../../../../../components/Mui/Formik/UploadImage";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
export default function AddEmployeeForm({ formikProps }) {
  return (
    <>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          placeholder="Enter your name"
          label="Name"
          name={EMPLOYEE_CONFIG_FIELD_NAME.NAME}
          id={EMPLOYEE_CONFIG_FIELD_NAME.NAME}
          // helperText="Please enter your name"
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<BadgeIcon />)}
        />
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          placeholder="Enter your mobile number"
          type="number"
          label="Phone Number"
          name={EMPLOYEE_CONFIG_FIELD_NAME.MOBILE_NO}
          id={EMPLOYEE_CONFIG_FIELD_NAME.MOBILE_NO}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<PhoneIcon />)}
        />
      </Stack>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          placeholder="Enter your full address"
          label="Address"
          name={EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS}
          id={EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<BusinessIcon />)}
        />
      </Stack>
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={TextField}
          placeholder="Enter Password"
          label="Password"
          name={EMPLOYEE_CONFIG_FIELD_NAME.PASSWORD}
          id={EMPLOYEE_CONFIG_FIELD_NAME.PASSWORD}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<KeyIcon />)}
        />
      </Stack>
      <Stack spacing={2} direction="row" alignItems={"center"}>
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={[
            {
              label: "Male",
              value: "Male",
            },
            {
              label: "Female",
              value: "Female",
            },
          ]}
          placeholder="Enter your gender"
          label="Gender"
          name={EMPLOYEE_CONFIG_FIELD_NAME.GENDER}
          id={EMPLOYEE_CONFIG_FIELD_NAME.GENDER}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<BusinessIcon />)}
        />
        <Field
          fullWidth
          variant={MUI_VARIANT}
          size={MUI_SIZE}
          component={SelectField}
          data={[
            {
              label: "1 ★",
              value: 1,
            },
            {
              label: "2 ★",
              value: 2,
            },
            {
              label: "3 ★",
              value: 3,
            },
            {
              label: "4 ★",
              value: 4,
            },
            {
              label: "5 ★",
              value: 5,
            },
          ]}
          placeholder="Enter employee rating"
          label="Rating"
          name={EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING}
          id={EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING}
          sx={{
            height: MUI_TEXT_FIELD_HIGHT,
          }}
          InputProps={FieldIcon(<ReviewsIcon />)}
        />
      </Stack>
      <SpecialtyForm formikProps={formikProps} />
    </>
  );
}

const SpecialtyForm = ({ formikProps }) => {
  const { isLoading } = usePaginatedServices(1, 9999, true);
  const serviceDataContent =
    useSelector((state) => state.services.serviceData?.content) || [];

  const specialtyList = serviceDataContent
    ?.filter((item) => item?.status === "ACTIVE")
    ?.map((item) => ({
      label: item?.serviceName,
      value: item?.id,
    }));

  function getImg(image) {
    if (typeof image === "string") {
      return image;
      // eslint-disable-next-line no-undef
    } else if (image instanceof File) {
      // Handle File type
      // eslint-disable-next-line no-undef
      return URL.createObjectURL(image);
      // eslint-disable-next-line no-undef
    } else if (image instanceof Blob) {
      // Handle Blob type
      // eslint-disable-next-line no-undef
      return URL.createObjectURL(image);
    } else {
      return undefined;
    }
  }

  if (isLoading) return <LinearProgress />;

  return (
    <Stack>
      <Field
        component={MultiSelectFormik}
        name={EMPLOYEE_CONFIG_FIELD_NAME.SPECIALTY}
        id={EMPLOYEE_CONFIG_FIELD_NAME.SPECIALTY}
        fullWidth
        variant={MUI_VARIANT}
        size={MUI_SIZE}
        data={specialtyList}
        placeholder="Select specialty"
        label="Specialty"
        sx={{
          height: MUI_TEXT_FIELD_HIGHT,
        }}
        Icon={ContentCutIcon}
      />
      {/* <Field
        fullWidth
        variant={MUI_VARIANT}
        size={MUI_SIZE}
        component={TextField}
        placeholder="Enter your full address"
        label="Address"
        name={EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS}
        id={EMPLOYEE_CONFIG_FIELD_NAME.ADDRESS}
        sx={{
          height: MUI_TEXT_FIELD_HIGHT,
        }}
        InputProps={FieldIcon(<ImageIcon />)}
      /> */}
      <Field
        fullWidth
        variant={MUI_VARIANT}
        size={MUI_SIZE}
        component={UploadImage}
        placeholder="Upload staff image"
        label="Image"
        type="file"
        name={EMPLOYEE_CONFIG_FIELD_NAME.IMG_UPLOAD}
        id={EMPLOYEE_CONFIG_FIELD_NAME.IMG_UPLOAD}
        sx={{
          height: MUI_TEXT_FIELD_HIGHT,
        }}
        // helperText="Please enter your name"
        InputProps={FieldIcon(<AddPhotoAlternateIcon />)}
      />
      <Stack direction={"row"} spacing={2}>
        <img
          // src={`${IMG_URL.BASE_SERVICE_IMG}${f?.values?.imageS3path}`}
          src={
            getImg(
              formikProps?.values?.imgUpload
                ? formikProps?.values?.imgUpload
                : formikProps?.values?.imageUrl
            ) || " "
          }
          alt=""
        />

        {/* Read Img */}
      </Stack>
    </Stack>
  );
};

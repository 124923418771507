import { createSlice } from "@reduxjs/toolkit";

export const EmployeeTimeSheetSlice = createSlice({
    name: "Timesheet",
    initialState : [],
    reducers : {
        setTimeSheet: (state, action) => {
            state.push(action.payload)
        }
    }
})


export const { setTimeSheet } = EmployeeTimeSheetSlice.actions ;
export default EmployeeTimeSheetSlice.reducer ;
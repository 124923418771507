import React from "react";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { Form, Formik } from "formik";
import { Button, Stack } from "@mui/material";
import { IOpenVendorForm } from "../VendorTable";
import { vendorInitialValue } from "../formModal/vendorInitialValue";
import { CustomizedDialog } from "../../../../../../components/Mui/feedback/Dialog";
import { validationSchemaVendor } from "../formModal/validationSchemaVendor";
import { IVendorEntity } from "../../../SystemManagement/ConfigureServices/types/configService";
import VendorFormUi from "./VendorFormUi";
import { useCreateVendor, useUpdateVendor } from "../../../../../../hooks/api/salonService/vendorController/useAllVendor";

interface IVendorFormProps {
  openVendorForm: IOpenVendorForm;
  handleCloseVendorForm: () => void;
}

const VendorForm = ({
  openVendorForm,
  handleCloseVendorForm,
}: IVendorFormProps) => {
  const { createVendor } = useCreateVendor();
  const { updateVendor } = useUpdateVendor();

  const updateVendorFn = async (values: IVendorEntity) => {
    await updateVendor(values);
    handleCloseVendorForm();
  };
  const createVendorFn = async (values: IVendorEntity) => {
    await createVendor(values);
    handleCloseVendorForm();
  };

  const handleSubmit = async (values: IVendorEntity) => {
    if (openVendorForm.mode === "EDIT") {
      await updateVendorFn({ ...values, id: openVendorForm.data?.id });
      return;
    }
    if (openVendorForm.mode === "ADD") {
      await createVendorFn({
        ...values,
      });
      return;
    }
  };

  return (
    <div>
      <CustomizedDialog
        openDialog={openVendorForm?.open}
        handleCloseDialog={handleCloseVendorForm}
        title={openVendorForm?.mode === "EDIT" ? "Edit Vendor" : "Add Vendor"}
        disableClose={false}
        icon={<ShoppingCartCheckoutIcon />}
        maxWidth="sm"
        isActionButtonRequired={false}
        actionButtons={<></>}
      >
        <Formik
          initialValues={vendorInitialValue(openVendorForm)}
          validationSchema={validationSchemaVendor}
          onSubmit={handleSubmit}
          enableReinitialize
          validateOnChange={true}
        >
          {(formikProps) => (
            <Form id="add-product-form">
              <VendorFormUi formikProps={formikProps} />
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <Button type="submit" variant="contained">
                  {openVendorForm.mode === "ADD" ? "Save" : "Update"}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </CustomizedDialog>
    </div>
  );
};

export default VendorForm;

import { BaseCard } from "../../../../../components/Mui";
import ServiceCatTable from "./ServiceCatTable";

const ServiceCategory = () => {
  return (
    <BaseCard>
      <ServiceCatTable />
    </BaseCard>
  );
};

export default ServiceCategory;

import {
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import EnhancedTableHead from "../../../../../components/Mui/table/Table";
import { headCellsServiceConfig } from "./SysManConstant";
import { IConfigServiceData, IContent, IProductList } from "./types/configService";
import { CustomizedDialog } from "../../../../../components/Mui/feedback/Dialog";

// icon
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import AddServiceForm from "./form/AddService";
import AddServiceCategory from "./form/AddServiceCategory";
import {
  usePaginatedServices,
  useUpdateServices,
} from "./configServiceApiHook";
import { useDispatch, useSelector } from "react-redux";

import { RState } from "../../../../../redux/feature/slice";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { SALON_SERVICE_ENDPOINT } from "../../../../../api/salon-api/salon-service";
import { setDeleteService } from "../../../../../redux/feature/salonServiceSlice";
// import useServiceCategory from "../../../../../hooks/api/useServiceCategory";

import {
  IDialogueModeServiceCategory,
  SERVICE_CATEGORY_FORM_MODE,
} from "../ServiceCategory/ServiceCatTable";
import { ServiceTableCell } from "./table/ServiceTableCell";

export interface IDialogueMode {
  mode: string;
  data: IContent | null;
}

export const SERVICE_FORM_MODE = {
  ADD: "add",
  EDIT: "edit",
};

const initialDialogueMode: IDialogueMode = {
  mode: SERVICE_FORM_MODE.ADD,
  data: null,
};

const ConfigServiceTable = () => {
  // Dialogue's state
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDialogueAddServiceCategory, setOpenDialogueAddServiceCategory] =
    useState(false);

  //Dialogue's state (edit mode)
  const [openDialogueEditService, setOpenDialogueEditService] =
    useState<IDialogueMode>(initialDialogueMode);

  //Table's state
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected] = useState([]);
  const serviceData = useSelector(
    (state: RState) => state?.services?.serviceData
  ) as IConfigServiceData;
  const serviceDataContent = serviceData?.content || [];
  const SalonDetail = useSelector((state: RState) => state?.SalonDetails?.Salon_data);
  const { updateService } = useUpdateServices();
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();

  const {
    isLoading,
    currentPage: page,
    pageSize: rowsPerPage,
    setPage,
    setSize: setRowsPerPage,
  } = usePaginatedServices(1, 5);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCloseDialogueAddService = () => {
    setOpenDialogue(false);
    setOpenDialogueEditService(initialDialogueMode);
  };
  const handleOpenDialogueAddService = () => {
    setOpenDialogue(true);
  };

  const handleCloseDialogueAddServiceCategory = () => {
    setOpenDialogueAddServiceCategory(false);
  };
  const handleOpenDialogueAddServiceCategory = () => {
    setOpenDialogueAddServiceCategory(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (newPage < 1) return;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleChangeSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: IContent
  ) => {
    const checked = event.target.checked;
    const requestBody = {
      id: row?.id,
      salonId: SalonDetail?.id,
      serviceName: row?.serviceName,
      avgServeTime: row?.avgServeTime,
      gender: row?.gender,
      serviceCategoryId: row?.serviceCategory.id,
      productCategoryId: row?.productCategoryId || 1,
      productIdsList: row?.productsList?.map((item: IProductList) => item.productId) || [] ,
      description: row?.description,
      price: row?.price,
      status: checked ? 0 : 1,
    };
    try {
      updateService(requestBody);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleOpenDialogueEditService = (row: IContent) => {
    setOpenDialogueEditService({
      mode: SERVICE_FORM_MODE.EDIT,
      data: row,
    });
    handleOpenDialogueAddService();
  };

  const handleDeleteService = async (row: IContent) => {
    try {
      await axiosPrivate.delete<IContent>(
        `${SALON_SERVICE_ENDPOINT}/${row.id}`
      );
      dispatch(setDeleteService(row.id));
    } catch (error: any) {
      console.error("error", error);
    }
  };

  const tableInclose = (
    <Table>
      <EnhancedTableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        rowCount={serviceDataContent.length}
        headCells={headCellsServiceConfig}
      />
      <TableBody>
        {serviceDataContent.map((row: IContent, index: number) => {
          const labelId = `service-table-${index}`;

          return (
            <ServiceTableCell
              key={index}
              row={row}
              index={index}
              labelId={labelId}
              handleChangeSwitch={handleChangeSwitch}
              handleOpenDialogueEditService={handleOpenDialogueEditService}
              handleDeleteService={handleDeleteService}
            />
          );
        })}
      </TableBody>
    </Table>
  );

  return (
    <Stack width={"100%"}>
      <TableContainer>
        <Toolbar>
          <Grid container spacing={2} alignItems="center" width={"45%"}>
            <Grid item>
              <SearchIcon color="inherit" style={{ display: "block" }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by Service Name"
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "default" },
                }}
                variant="standard"
                size="small"
              />
            </Grid>
            <Grid item xs>
              <Chip
                label="Search"
                onClick={() => {
                  console.log("search");
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" width={"55%"}>
            <Grid item xs></Grid>
            <Grid item>
              {/* <Button
                variant="contained"
                onClick={handleOpenDialogueAddServiceCategory}
              >
                Add Service Category
              </Button> */}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={handleOpenDialogueAddService}
              >
                Add Services
              </Button>
            </Grid>
          </Grid>
        </Toolbar>

        {isLoading ? (
          <LinearProgress />
        ) : !serviceDataContent || !serviceDataContent.length ? (
          <Typography variant="body1" align="center" sx={{ my: 2 }}>
            No data available
          </Typography>
        ) : (
          tableInclose
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={serviceData?.totalElements || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <AddService
        handleDialogueClose={handleCloseDialogueAddService}
        openDialogue={openDialogue}
        openDialogueEdit={openDialogueEditService}
        setOpenDialogueEdit={setOpenDialogueEditService}
      />
      <AddServiceCategoryMain
        handleDialogueClose={handleCloseDialogueAddServiceCategory}
        openDialogue={openDialogueAddServiceCategory}
      />
    </Stack>
  );
};

export default ConfigServiceTable;

interface IAddService {
  openDialogue: boolean;
  handleDialogueClose: () => void;
  openDialogueEdit: IDialogueMode;
  setOpenDialogueEdit: React.Dispatch<React.SetStateAction<IDialogueMode>>;
}

const AddService = ({
  openDialogue,
  handleDialogueClose,
  openDialogueEdit,
}: IAddService) => {
  return (
    <div>
      <CustomizedDialog
        openDialog={openDialogue}
        handleCloseDialog={handleDialogueClose}
        title={
          openDialogueEdit.mode === SERVICE_FORM_MODE.EDIT
            ? "Edit Service"
            : "Add Service"
        }
        disableClose={false}
        icon={<SettingsSuggestIcon />}
        maxWidth="md"
        isActionButtonRequired={false}
        actionButtons={<></>}
      >
        <AddServiceForm
          openDialogueEdit={openDialogueEdit}
          handleDialogueClose={handleDialogueClose}
        />
      </CustomizedDialog>
    </div>
  );
};

interface IAddServiceCategory {
  openDialogue: boolean;
  handleDialogueClose: () => void;
  openDialogueEditServiceCategory?: IDialogueModeServiceCategory;
}

export const AddServiceCategoryMain = ({
  openDialogue,
  handleDialogueClose,
  openDialogueEditServiceCategory,
}: IAddServiceCategory) => {
  return (
    <div>
      <CustomizedDialog
        openDialog={openDialogue}
        handleCloseDialog={handleDialogueClose}
        title={
          openDialogueEditServiceCategory?.mode ===
          SERVICE_CATEGORY_FORM_MODE.EDIT
            ? "Edit Service Category"
            : "Add Service Category"
        }
        disableClose={false}
        icon={<SettingsSuggestIcon />}
        maxWidth="sm"
        isActionButtonRequired={false}
        actionButtons={<></>}
      >
        <AddServiceCategory
          openDialogueEditServiceCategory={openDialogueEditServiceCategory}
          handleDialogueClose={handleDialogueClose}
        />
      </CustomizedDialog>
    </div>
  );
};

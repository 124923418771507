import React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UploadLogoModal from "./UploadLogoModal";

function UploadLogoForm({ handleNext, handleBack }) {
  const [openModal, setOpenModal] = React.useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Typography
        component="h1"
        variant="body1"
        sx={{ textAlign: "center" }}
        gutterBottom
      >
        Please upload a logo for the salon
      </Typography>
      <Box component="div" noValidate sx={{ my: 3 }}>
        <Grid container justifyContent="center">
          <Button variant="contained" onClick={handleOpenModal} sx={{ mb: 3 }}>
            Upload Logo
          </Button>
          <UploadLogoModal
            open={openModal}
            handleClose={handleCloseModal}
          />
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Button type="submit" onClick={handleNext} fullWidth variant="contained" sx={{ mb: 3 }}>
          Next
        </Button>
        <Button
          onClick={handleBack}
          fullWidth
          variant="outlined"
          sx={{ mb: 3 }}
        >
          Back
        </Button>
      </Box>
    </>
  );
}

export default UploadLogoForm;

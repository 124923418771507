
export const empRatings = [
  { label: 'Customer Satisfaction:', value: 2 },
  { label: 'Behavior:', value: 3 },
  { label: 'Quality of Service:', value: 5 },
  { label: 'Time Accuracy:', value: 1 },
];

export const EMP_FEEDBACK_DATA = {
  LABEL: 'Select the Employee',
  OVER_ALL: 'OVERALL',
  OVER_ALL_RATING: 'Over All Rating',
};

export const EMP_DATA_KEY = {
  NAME: 'name',
  STATUS: 'status',
  GENDER: 'gender',
  ID: 'id',
  OVER_ALL_RATING: 'overAllRating',
  MOBILE_NO: 'mobileNo',
  ADDRESS: 'address',
  CHECKED_IN: 'checkedIn',
  IS_AVAILABLE: 'isAvailable',
};

const typeData = {
  name: 'string',
  status: 'ACTIVE',
  gender: 'string',
  overAllRating: 0,
  id: 0,
  mobileNo: 0,
  address: 'string',
  checkedIn: true,
  isAvailable: true,
};

export const headCellsEmpFeedBack = [
  {
    id: EMP_DATA_KEY.NAME,
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: EMP_DATA_KEY.OVER_ALL_RATING,
    numeric: false,
    disablePadding: false,
    label: 'Rating',
  },
  {
    id: EMP_DATA_KEY.OVER_ALL_RATING,
    numeric: false,
    disablePadding: false,
    label: 'Star Rating',
  },
  {
    id: 'view',
    numeric: true,
    disablePadding: false,
    label: 'View',
  },
];

export const rowsEmployeeFeedBack = [
  {
    id: 1,
    name: 'John Doe',
    status: 'ACTIVE',
    gender: 'Male',
    overAllRating: 3.5,
    mobileNo: 1234567890,
    address: '123 Main St, Anytown USA',
    checkedIn: true,
    isAvailable: true,
  },
  {
    id: 2,
    name: 'Jane Smith',
    status: 'INACTIVE',
    gender: 'Female',
    overAllRating: 4,
    mobileNo: 9876543210,
    address: '456 Oak Ave, Anytown USA',
    checkedIn: false,
    isAvailable: false,
  },
  {
    id: 3,
    name: 'Michael Johnson',
    status: 'ACTIVE',
    gender: 'Male',
    overAllRating: 4.2,
    mobileNo: 5555555555,
    address: '789 Pine St, Anytown USA',
    checkedIn: true,
    isAvailable: true,
  },
  {
    id: 4,
    name: 'Emily Davis',
    status: 'INACTIVE',
    gender: 'Female',
    overAllRating: 2,
    mobileNo: 1111111111,
    address: '321 Elm St, Anytown USA',
    checkedIn: false,
    isAvailable: false,
  },
  {
    id: 5,
    name: 'David Wilson',
    status: 'ACTIVE',
    gender: 'Male',
    overAllRating: 4.9,
    mobileNo: 9999999999,
    address: '555 Pine St, Anytown USA',
    checkedIn: true,
    isAvailable: true,
  },
  {
    id: 6,
    name: 'Olivia Thompson',
    status: 'INACTIVE',
    gender: 'Female',
    overAllRating: 3.7,
    mobileNo: 8888888888,
    address: '777 Oak Ave, Anytown USA',
    checkedIn: false,
    isAvailable: false,
  },
];

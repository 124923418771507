// "salonId": 0,
// "productId": 0,
// "vendorId": 0,
// "quantity": 0,
// "threshold": 0,
// "measurementUnit": "MILLILITER",
// "status": "ACTIVE",
// "description": "string"

export const headCellsInventory = [
   
  {
    id: "MeasurementUnit",
    numeric: false,
    disablePadding: false,
    label: "Measure Unit",
  },
    {
      id: "Quantity",
      numeric: false,
      disablePadding: false,
      label: "Quantity",
    },
    {
      id: "Threshold",
      numeric: false,
      disablePadding: false,
      label: "Threshold",
    },
    {
      id: "Product",
      numeric: false,
      disablePadding: false,
      label: "Product",
    },
    {
      id: "Status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];
  
import { CONFIG_SERVICE_FIELD_NAME } from "./initialValueConfigService";
import * as Yup from "yup";
export const validationConfigService = Yup.object().shape({
  [CONFIG_SERVICE_FIELD_NAME.SERVICE_NAME]: Yup.string().required(
    "Service name is required"
  ),
  [CONFIG_SERVICE_FIELD_NAME.SERVICE_TIME]: Yup.number()
    .required("Service time is required")
    .typeError("Service time should be a number"),
  [CONFIG_SERVICE_FIELD_NAME.SERVICE_GENDER]: Yup.string().required(
    "Service gender is required"
  ),
  [CONFIG_SERVICE_FIELD_NAME.SERVICE_CATEGORY_ID]: Yup.string().required(
    "Service category is required"
  ),
  [CONFIG_SERVICE_FIELD_NAME.SERVICE_PRICE]: Yup.number()
    .required("Service price is required")
    .typeError("Service price should be a number"),
});

export const initialValueServiceCategory = Yup.object().shape({
  categoryName: Yup.string().required("Category name is required"),
  description: Yup.string()
    .required("Description is required")
    .min(2, "Min 2 characters")
    .max(100, "Max 100 characters"),
  status: Yup.string().required("Status is required"),
  gender: Yup.string().required("Gender is required"),
});

import { useSelector, useDispatch } from "react-redux";
import { AuthState, setLogin as _setLogin } from "../redux/feature/authSlice";

export const useAuth = () => {
  const dispatch = useDispatch();
  const  auth  = useSelector((state: AuthState) => state.auth);

  const setLogin = (auth: AuthState) => {
    dispatch(_setLogin(auth));
  };
  return { auth, setLogin };
};

import axios from "axios";
import { BASE_URL } from "../constants/baseURL";

export const axiosPrivateAuthService = axios.create({
  baseURL: BASE_URL.SALON_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivateFeedbackService = axios.create({
  baseURL: BASE_URL.FEEDBACK_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosPrivateLogBookService = axios.create({
  baseURL: BASE_URL.LOG_BOOK_SERVICE,
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosPrivateInventoryService = axios.create({
  baseURL: BASE_URL.INVENTORY_SERVICE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

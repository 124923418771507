import React, { useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Button as MUIButton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import PopWindow from "../../../components/CustomerComponent/Checkout/PopWindow";
import { truncateText } from "../../../helper/Common/text";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { LOGBOOK_ENDPOINT } from "../../../api/salon-api/log-book-service";
import { useAxiosPrivateLogBook } from "../../../hooks/useAxiosPrivate";
function ItemsView({ data:serviceData,setOrderBooks }) {
  const [isChange, setIsChange] = useState(false);
  const [orderId, setOrderId] = useState(null)

  const handleIsChange = (id) => {
    setOrderId(id)
    setIsChange(!isChange);
  };

  if (serviceData?.length === 0) {
    return (
      <div>
        <Typography variant="h5" component="p">
          No Items
        </Typography>
      </div>
    );
  }
  return (
    <>
      <Stack>
        <Typography variant="h5" component="p">
          Items
        </Typography>
        <Stack display={"flex"} flexWrap={"wrap"}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Staff Name</TableCell>
                  <TableCell align="right">Service</TableCell>
                  <TableCell align="right">Rating(5) </TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Gender</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              {serviceData &&
                serviceData?.length > 0 &&
                serviceData?.map((item) => {
                  return (
                    <TableBody>
                      <TableRow
                        key={item?.salonStaffEntity?.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="item">
                          {truncateText(item?.salonStaffEntity?.name, 10)}
                        </TableCell>
                        <TableCell align="right">
                          {item?.salonServicesEntity?.serviceName}
                        </TableCell>
                        <TableCell align="right">
                          {item?.salonStaffEntity?.overAllRating}
                        </TableCell>
                        <TableCell align="right">
                          {item?.salonServicesEntity?.price}
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          {item?.salonStaffEntity?.gender}
                        </TableCell>
                        <TableCell align="right">
                          <MUIButton
                            onClick={handleIsChange.bind(null,item.id)}
                            variant="contained"
                            color="primary"
                          >
                            Change Stylist
                          </MUIButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })}
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <PopWindow
        isOpen={isChange}
        handleIsOpen={handleIsChange}
        orderBookId={orderId}
        setOrderBooks={setOrderBooks}
      />
    </>
  );
}

export default ItemsView;

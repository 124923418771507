import * as Yup from 'yup';
import { SIGNUP_FIELD_NAME } from './initialValue';

// Form validation schema
export const validationSchemaSignup = Yup.object().shape({
  [SIGNUP_FIELD_NAME.PHONE_NUMBER]: Yup.string()
    .required('Phone number is required')
    .matches(/^\d{10}$/, 'Phone number should be 10 digits'),
  [SIGNUP_FIELD_NAME.FULL_NAME]: Yup.string()
    .required('Full Name is required')
    .trim()
    .min(3, 'Full Name should be at least 3 characters')
    .max(50, 'Full Name should be less than 50 characters'),

  [SIGNUP_FIELD_NAME.GENDER]: Yup.string().required('Gender is required'),
  [SIGNUP_FIELD_NAME.EMAIL]: Yup.string().email('Invalid email'),
});

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SALON_ONBOARDING_ENDPOINT, UPLOAD_LOGO_ENDPOINT } from "../../../../../api/salon-api/salon-service";
import { setLogo } from "../../../../../redux/feature/uploadLogoSlice";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { setDetails } from "../../../../../redux/feature/salonDetailsSlice";

export default function UpdateLogo() {
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const UploadLogo = useSelector((state) => state.UploadLogo);
  const Auth = useSelector((state) => state.auth)
  const [logoUrl, setLogoUrl] = React.useState("");

  const handleLogoChange = async (event, id = Auth.data.salonId) => {
    const file = event.target.files[0];
    if (file) {
      // setLogoFile(file);
      console.log("File uploaded:", file);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axiosPrivate({
          method: "PUT",
          url: `${UPLOAD_LOGO_ENDPOINT}/update/${id}`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        GetDetails()
        const imageUrl = response.data.presignedUrl.split("?")[0];
        setLogoUrl(imageUrl);
        dispatch(setLogo(imageUrl));
        toast("Logo Uploaded");
      } catch (error) {
        console.error("Error Uploading Logo:", error);
        toast("Error Uploading Logo");
      }
    }
  };


  const GetDetails = async (id = Auth.data.salonId) => {
    try {
        const response = await axiosPrivate({
          method: 'GET',
          url: `${SALON_ONBOARDING_ENDPOINT}/${id}`,
        });
        dispatch(setDetails(response.data));
    } catch (error) {
      console.error('Error fetching Salon Details:', error);
    }
  }

  const handleUploadClick = () => {
    document.getElementById("logo-upload").click();
  };

  return (
    <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
      <Paper style={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography color="textSecondary" variant="h5" align="center">
                  Update Logo
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
              <Grid item>
                {/* <Button
                  component="label"
                  fullWidth
                  variant="contained"
                  sx={{ my: 3 }}
                >
                  <input
                    type="file"
                    style={{ display: 'none' }}
                  // onChange={handleFileChange}
                  />
                  Upload file <DriveFolderUploadIcon sx={{ mx: 1 }} />
                </Button> */}

                <input
                  type="file"
                  id="logo-upload"
                  style={{ display: "none" }}
                  onChange={handleLogoChange}
                />
                <Button
                  onClick={handleUploadClick}
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 3 }}
                >
                  <DriveFolderUploadIcon sx={{ mr: 1 }} /> Upload Logo
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Typography
          style={{ margin: "10px 16px" }}
          color="textSecondary"
          align="center"
        >
          The logo should be in Landscape mode
        </Typography>
        <Typography
          style={{ margin: "10px 16px" }}
          color="textSecondary"
          align="center"
        >
          The Logo size should be under 1 Mb
        </Typography>

        <Typography
          style={{ margin: "40px 16px" }}
          color="textSecondary"
          align="center"
        >
          Current Logo
        </Typography>

        <Stack justifyContent={"center"} alignItems={"center"} marginBottom={10}>
          <Stack className="min-w-[300px] max-w-[400px] min-h-[300px] max-h-[400px] flex justify-center items-center border-4 border-gray-400 rounded-md p-4 overflow-hidden">
            <img src={UploadLogo.logo} className="img rounded" alt="Smart Salon Bot"/>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

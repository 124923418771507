import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import {
  IConfigServiceData,
  IContent,
  IRequestBodyAddService,
} from "./types/configService";
import {
  SALON_SERVICE_ENDPOINT,
  SERVICE_ALL_ENDPOINT,
} from "../../../../../api/salon-api/salon-service";
import {
  setServiceData,
  setUpdateService,
} from "../../../../../redux/feature/salonServiceSlice";
import { RState } from "../../../../../redux/feature/slice";
import { handleUploadServiceImg } from "./form/formModal/requestFact";
import { toast } from "react-toastify";

export const usePaginatedServices = (
  initialPage = 1,
  initialSize = 5,
  all = false
) => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialSize);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const serviceData = useSelector(
    (state: RState) => state.services.serviceData
  );
  const salonId = useSelector((state: RState) => state?.auth.salonId);
  console.log(salonId, "salonId___");
  // const sdfdsf = serviceData.

  const fetchService = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const params = all
        ? {
            page: currentPage,

            salonId: salonId,
          }
        : {
            salonId: salonId,
          };

      const response = await axiosPrivate.post<IConfigServiceData>(
        SERVICE_ALL_ENDPOINT,
        {
          ...params,
          size: 9999,
        }
      );

      if (response.status === 200) {
        dispatch(setServiceData(response.data));
      } else {
        setError(new Error("Failed to fetch services"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error fetching services:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      currentPage !== serviceData?.page ||
      pageSize !== serviceData?.totalElements
    ) {
      fetchService();
    }
  }, [currentPage, pageSize]);

  const setPage = (page: number) => {
    setCurrentPage(page);
  };

  const setSize = (size: number) => {
    setPageSize(size);
  };

  return {
    isLoading,
    error,
    currentPage,
    pageSize,
    setPage,
    setSize,
    serviceData,
  };
};

export const useUpdateServices = () => {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const updateService = async (data: IRequestBodyAddService) => {
    setIsLoading(true);
    setError(null); // Clear any previous errors

    try {
      const response = await axiosPrivate.put<IContent>(
        `${SALON_SERVICE_ENDPOINT}/${data.id}`,
        { ...data }
      );
      toast.success("Service Updated");
      let imgResponse = { key: null };
      if (data.imgUpload) {
        imgResponse = await handleUploadServiceImg(
          data,
          axiosPrivate,
          response.data?.id
        );
      }

      if (response.status === 200) {
        dispatch(
          setUpdateService({
            ...response.data,
            imageS3path: imgResponse.key
              ? imgResponse.key
              : response.data?.imageS3path,
          })
        );
      } else {
        setError(new Error("Failed to update service"));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error updating service:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    updateService,
  };
};

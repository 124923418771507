import { AxiosInstance } from "axios";
import {
  IRequestBodyAddService,
  IServiceCategory,
} from "../../types/configService";
import { SALON_SERVICE_UPLOAD_IMG } from "../../../../../../../api/salon-api/salon-service";
import { toast } from "react-toastify";

export const requestBodyAddService = (data: IRequestBodyAddService) => {
  //   delete data.productCategoryId;
  return {
    ...data,
  };
};

export const requestBodyAddServiceCategory = (
  data: IServiceCategory,
  mode?: string
) => {
  mode === "edit" && delete data.id;
  return {
    ...data,
  };
};

export const handleUploadServiceImg = async (
  values: IRequestBodyAddService,
  axiosPrivate: AxiosInstance,
  id?: number
) => {
  // upload img api here
  const formData = new FormData();
  formData.append("file", values.imgUpload as Blob);
  formData.append("salonServiceId", id?.toString() || "");
  try {
    const response = await axiosPrivate.post(
      SALON_SERVICE_UPLOAD_IMG,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set appropriate Content-Type
        },
      }
    );

    toast.success("Service Image Uploaded");
    return response.data;
  } catch (error) {
    toast.error("Error Uploading Logo");
    return error;
  }
  // dispatch(setAddService(response.data));
  // handleDialogueClose();
  // console.log("response", response);
};

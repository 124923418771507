import React from "react";
import Category from "../../../components/CustomerComponent/Categories/Category";

const SelectCategory = () => {
  return (
    <div className="row-span-9">
      <div className="h-full w-full overflow-y-scroll hide-scrollbar">
        <Category />
      </div>
    </div>
  );
};

export default SelectCategory;

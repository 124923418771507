import { useMemo, useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';

import { Chip, Grid, Rating, Stack, TextField, LinearProgress, Typography } from '@mui/material';
import { EMP_DATA_KEY, headCellsEmpFeedBack, rowsEmployeeFeedBack } from './EmpConstant';
import { useSelector, useDispatch } from 'react-redux';

import EnhancedTableHead from '../../../../../components/Mui/table/Table';
import { getComparator, stableSort } from '../../../../../helper';
import { FEEDBACK_ALL_ENDPOINT } from "../../../../../api/feedback-api/feedback-service";
import { useAxiosPrivateFeedback } from '../../../../../hooks/useAxiosPrivate';
import { setFeedback } from '../../../../../redux/feature/feedbackSlice';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

export function FeedbackEmpTable({ handleDialogueOpen }) {
  const { allFeedback, paginationData } = useSelector((state) => state.Feedback);
  const axiosPrivateFeedback = useAxiosPrivateFeedback();
  const dispatch = useDispatch();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isGetResponse, setIsGetResponse] = useState(true);
  const [message, setMessage] = useState("Unable to fetch data");
  const SalonDetails = useSelector((state) => state.SalonDetails?.Salon_data)

  const getFeedbackData = async () => {
    try {
      const response = await axiosPrivateFeedback({
        method: "GET",
        url: `${FEEDBACK_ALL_ENDPOINT}?page=${page + 1}&size=${rowsPerPage}&salonId=${SalonDetails?.id}`,
      });
      dispatch(setFeedback(response.data));
      setIsGetResponse(false);
      if (response?.data?.content.length === 0) {
        setMessage("No Data Available");
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setIsGetResponse(false);
      setMessage("Unable to fetch data");
    }
  };

  useEffect(() => {
    getFeedbackData();
  }, [page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rowsEmployeeFeedBack.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () =>
      stableSort(rowsEmployeeFeedBack, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <Stack width={'100%'}>
      <TableContainer>
        <Toolbar>
          <Grid container spacing={2} alignItems='center' width={'45%'}>
            <Grid item>
              <SearchIcon color='inherit' style={{ display: 'block' }} />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder='Search by Name or Employee ID'
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: 'default' },
                }}
                variant='standard'
                size='small'
              />
            </Grid>
            <Grid item xs>
              <Chip label='Search' />
            </Grid>
          </Grid>
        </Toolbar>
        {allFeedback.length > 0 ? (
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsEmployeeFeedBack.length}
              headCells={headCellsEmpFeedBack}
            />
            <TableBody>
              {allFeedback.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `employee-table-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={() => handleDialogueOpen(row.id, row)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    size={'medium'}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell component='th' id={labelId} scope='row' padding='none'>
                      {row.userName}
                    </TableCell>
                    <TableCell align='left'>{row.rating}</TableCell>
                    <TableCell align='left'>
                      <Rating readOnly value={row.rating} precision={0.1} />
                    </TableCell>
                    <TableCell align='right'>
                      <RemoveRedEyeIcon />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : isGetResponse ? (
          <LinearProgress />
        ) : (
          <Typography variant='body1' sx={{ textAlign: 'center' }} gutterBottom>
            {message}
          </Typography>
        )}
      </TableContainer>
      {paginationData?.totalPages && allFeedback.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component='div'
          count={paginationData.totalElements || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );
}

import React from "react";
import Category from "../../../components/CustomerComponent/Categories/Category";
import Header from "../../../components/CustomerComponent/Header/Header";
import { Outlet } from "react-router-dom";

function Dashboard() {
  return (
    <>
      <div className="">
        <Header />
        <div className="mt-14">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Dashboard;

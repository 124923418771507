import * as React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { blue } from "@mui/material/colors";
import { Button, DialogActions, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

export default function SimpleDialog({ isOpen, handleIsOpen, REPORT_API_ENDPOINT }) {
  const [isInput, setIsInput] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState(false)

  const SalonData = useSelector((state) => state?.SalonDetails?.Salon_data)

  const axiosPrivate = useAxiosPrivate()
  function addNewEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== "" && emailRegex.test(email)) {
      setEmails([...emails, email]);
      setEmail("");
      setIsInput(false);
    } else {
      toast.error("Please enter a valid email address");
    }
  }

  function removeEmail(email) {
    setEmails(emails.filter((item) => item !== email));
  }

  const handleSend = async() => {
  
    setLoading(true);
    const allEmails = [...new Set([...emails, `${SalonData?.salonManagerEmail}`, `${SalonData?.salonZonalManagerEmail}`])];
  
    try {
      await axiosPrivate({
        method: 'POST',
        url: REPORT_API_ENDPOINT,
        data: { receiverEmails: allEmails, salonId: SalonData?.id }
      });
      toast("Reports Sent Successfully");
    } catch (error) {
      toast.error('Something went wrong! cannot send Reports');
    } finally {
      setLoading(false);
      handleIsOpen();
      setEmails([]);
    }
  }

  


  return (
    <Dialog onClose={handleIsOpen} open={isOpen} sx={{ overflow: "hidden" }}>
      <DialogTitle>Send Report</DialogTitle>
      <Typography variant="body2" sx={{ mx: 3, textWrap: "wrap" }}>
        The reports will be sent to the Manager and the Owner. If you want to
        send them to another person, please add their email address.
      </Typography>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Salon Manager( ${SalonData?.salonManagerEmail} )`} />
          </ListItemButton>
        </ListItem>
        <ListItem disableGutters>
          <ListItemButton>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`Salon Zonal Manager( ${SalonData?.salonZonalManagerEmail} )`} />
          </ListItemButton>
        </ListItem>
        {emails.map((email) => (
          <ListItem disableGutters key={email}>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email} />
              <CloseIcon onClick={() => removeEmail(email)} />
            </ListItemButton>
          </ListItem>
        ))}
        {isInput ? (
          <ListItem disableGutters sx={{ mx: 2 }}>
            <ListItemAvatar>
              <Avatar>
                <CloseIcon onClick={() => setIsInput(false)} />
              </Avatar>
            </ListItemAvatar>
            <TextField
              placeholder="Enter email"
              variant="outlined"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              variant="contained"
              color="info"
              sx={{ mx: 2 }}
              onClick={() => addNewEmail(email)}
            >
              Add Email
            </Button>
          </ListItem>
        ) : (
          <ListItem disableGutters>
            <ListItemButton autoFocus onClick={() => setIsInput(true)}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add account" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <DialogActions>
        <Button onClick={handleIsOpen}>Cancel</Button>
        <LoadingButton loading={loading} onClick={handleSend}>Send</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

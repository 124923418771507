import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Chip, Divider } from "@mui/material";

export default function TaxConfiguration() {

  const SalonDetails = useSelector((state) => state.SalonDetails?.Salon_data)

  return (
    <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
      <Paper style={{ maxWidth: 936, margin: "auto", overflow: "hidden" }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)", marginBottom: "16px" }}
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <Typography color="textSecondary" variant="h5" align="center">
                  Tax Configuration
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs></Grid>
            </Grid>
          </Toolbar>
        </AppBar>

       <Grid container direction={"column"} marginY={3} paddingX={3} gap={2}>
       <Divider>
            <Chip label="Salon GST Configuration" size="medium" />
        </Divider>
        <Grid item>
        <Typography
          color="textSecondary"
          align="left"
        >
          GST Number: {SalonDetails?.gstNumber || "NA"}
        </Typography>
        </Grid>
        {/* <Grid item>
        <Typography
          color="textSecondary"
          align="left"
        >
          Salon Password: {SalonDetails?.clientCredentials || "NA"}
        </Typography>
        </Grid> */}
       
       </Grid>
      </Paper>
    </Box>
  );
}

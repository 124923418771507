import React from 'react'
import VendorTable from './VendorTable'
import { BaseCard } from '../../../../../components/Mui'

const Venders = () => {
  return (
    <BaseCard>
      <VendorTable />
    </BaseCard>
  )
}

export default Venders
export const services_customer = [
    {
      name: "Salon",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/at-home-salon-main-category-23-11-22.jpg",
      description:
        "Experience professional salon services with expert beauticians providing haircuts, styling, and coloring. Enjoy a luxurious salon experience tailored to your preferences.",
    },
    {
      name: "Male Grooming",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/male-grooming-main-category-23-11-22.jpg",
      description:
        "Our male grooming services include haircuts, beard trims, and skincare treatments. Benefit from top-notch grooming by skilled professionals, enhancing your appearance and confidence.",
    },
    {
      name: "Pre Bridal",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/pre-bridal-main-category-23-11-22.jpg",
      description:
        "Prepare for your big day with our comprehensive pre-bridal services. We offer skin treatments, hair styling, and makeup trials to ensure you look radiant and flawless on your wedding day.",
    },
    {
      name: "Advance Facials",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/led-facial-main-category-23-11-22.jpg",
      description:
        "Experience cutting-edge skincare with our advanced facials. From LED treatments to deep cleansing, our facials address various skin concerns, providing rejuvenation and a glowing complexion tailored to your needs.",
    },
    {
      name: "Spa",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/female-spa-main-category-23-11-22.jpg",
      description:
        "Indulge in our luxurious spa services, including massages, body scrubs, and relaxation treatments. Our certified therapists provide a serene and rejuvenating spa experience to enhance your well-being.",
    },
    {
      name: "Male Spa",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/male-massage-main-category-23-11-22.jpg",
      description:
        "Experience specialized male spa services with therapeutic massages and relaxation treatments. Our skilled therapists focus on relieving stress and enhancing your overall well-being.",
    },
    {
      name: "Hair Studio",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/female-hair-salon-main-category-23-11-22.jpg",
      description:
        "Our Hair Studio services include expert haircuts, coloring, and styling. Whether you're looking for a classic look or a trendy makeover, our professional stylists deliver personalized results to elevate your style.",
    },
    {
      name: "Nail Services",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/nail-services-main-category-11-1-22.jpg",
      description:
        "Pamper your nails with our professional nail services, including manicures, pedicures, and nail art. Our skilled technicians provide high-quality nail care and design to keep your nails looking stunning.",
    },
    {
      name: "MakeUp",
      imgUrl:
        "https://cdn.yesmadam.com/images/live/category/makeup-main-category-23-1-23.jpg",
      description:
        "Receive professional makeup application with our expert artists. Using high-quality products, we create a flawless look for any occasion, ensuring you look your best for events or everyday beauty.",
    },
  ];
  
import { createSlice } from "@reduxjs/toolkit";

export const SalonDetailsSlice = createSlice({
    name: "SalonDetails",
    initialState : { Salon_data: [] },
    reducers : {
        setDetails: (state, action) => {
            state.Salon_data = {...state.Salon_data, ...action.payload}
        }
    }
})


export const { setDetails } = SalonDetailsSlice.actions ;
export default SalonDetailsSlice.reducer ;
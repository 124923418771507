import { createSlice } from '@reduxjs/toolkit';

const initialServiceState = {
  services: [],
  totalPrice: 0
}

export const SelectedServiceSlice = createSlice({
  name: 'selectedService',
  initialState: initialServiceState,
  reducers: {
    setSelectedService: (state, action) => {
      state.services = [...action.payload];
      state.totalPrice = state.services.reduce((total, item) => total + item.cost, 0);
    },
    addSelectedService: (state, action) => {
      state.services.push({ ...action.payload });
      state.totalPrice = state.services.reduce((total, item) => total + item.cost, 0);
    },
    removeSelectedService: (state, action) => {
      state.services = state.services.filter((item) => item.salonServicesId !== action.payload);
      state.totalPrice = state.services.reduce((total, item) => total + item.cost, 0);
    },
    removeAllSelectedService: (state, action) => {
      return initialServiceState
    }
  },
});

export const {
  setSelectedService,
  addSelectedService,
  removeSelectedService,
  removeAllSelectedService
} = SelectedServiceSlice.actions;

export default SelectedServiceSlice.reducer;

export const headCellsVendors = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: false,
    label: "Full Address",
  },
  {
    id: "operations",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Stack,
  TablePagination,
  Switch,
  Avatar,
  LinearProgress,
} from "@mui/material";
// icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import EnhancedTableHead from "../../../../../../components/Mui/table/Table";
import {
  EMPLOYEE_CONFIG_FIELD_NAME,
  EMP_CONFIG_MODE,
  EMP_CONFIG_STATUS,
  headCellsEmpConfig,
} from "../constantEmpConfig";
import { CustomizedDialog } from "../../../../../../components/Mui/feedback/Dialog";
import usePaginatedEmployees, { useUpdateEmployee } from "../configApiHook";
// import usePaginatedEmployees from "../configApiHook";

export function EmpConfigTable({ handleModeAndData }) {
  const employeeDataFull =
    useSelector((state) => state?.Employee?.employeeData) || {};
  const employeeData = employeeDataFull?.content || [];

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected] = useState([]);
  const { updateEmployee } = useUpdateEmployee();
  const {
    isLoading,
    currentPage: page,
    pageSize: rowsPerPage,
    setPage,
    setSize: setRowsPerPage,
  } = usePaginatedEmployees(1, 5);

  const [openViewDialog, setOpenViewDialog] = useState({
    isOpen: false,
    employee: {},
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  /**
   * @abstract For stable sorting
   * @param {*} order
   * @property This Feature may be enabled in future
   */
  // const visibleRows = useMemo(
  //   () =>
  //     stableSort(employeeData, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );

  const dataLen = employeeData?.length || 0;

  const handleDialogueOpen = (event, employee) => {
    // console.log(employee)
    setOpenViewDialog({
      isOpen: true,
      employee: employee,
    });
  };

  const handleDialogueClose = () => {
    setOpenViewDialog({ isOpen: false, employee: {} });
  };
  if (isLoading || !employeeDataFull || !employeeData) {
    return <LinearProgress />;
  }

  const handleStatusChange = async (event, rowData) => {
    const { checked } = event.target;
    try {
      const data = {
        ...rowData,
        [EMPLOYEE_CONFIG_FIELD_NAME.STATUS]: checked
          ? EMP_CONFIG_STATUS.HOLD
          : EMP_CONFIG_STATUS.UNHOLD,
        serviceIdList: rowData.servicesList.map((ele) => `${ele.id}`),
      };
      delete data.servicesList
      await updateEmployee(data);
    } catch (error) {
      // eslint-disable-next-line no-undef
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Stack width={"100%"}>
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={dataLen}
              headCells={headCellsEmpConfig}
            />
            <TableBody>
              {employeeData.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `employee-config-table-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    size={"medium"}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      <Stack ml={2}>{row[EMPLOYEE_CONFIG_FIELD_NAME.ID]}</Stack>
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row[EMPLOYEE_CONFIG_FIELD_NAME.NAME]}
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Switch
                        checked={
                          row[EMPLOYEE_CONFIG_FIELD_NAME.STATUS] ===
                          EMP_CONFIG_STATUS.HOLD
                        }
                        onChange={(e) => handleStatusChange(e, row)}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row[EMPLOYEE_CONFIG_FIELD_NAME.OVER_ALL_RATING]} ★
                    </TableCell>

                    {/* This cell need to be changed externally api call attendance */}
                    {/* <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Switch
                        checked={row[EMPLOYEE_CONFIG_FIELD_NAME.IS_AVAILABLE]}
                        onChange={(e) => handleAvailabilityChange(e, row)}
                      />
                    </TableCell> */}
                    {/* <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Switch
                        checked={row[EMPLOYEE_CONFIG_FIELD_NAME.CHECKED_IN]}
                        onChange={(e) => handleCheckedInChange(e, row)}
                      />
                    </TableCell> */}

                    {/* <TableCell align='left'>{row[EMP_DATA_KEY.OVER_ALL_RATING]}</TableCell> */}

                    <TableCell
                      align="right"
                      onClick={(event) => handleDialogueOpen(event, row)}
                    >
                      <RemoveRedEyeIcon />
                    </TableCell>

                    <TableCell
                      align="right"
                      onClick={() =>
                        handleModeAndData(EMP_CONFIG_MODE.EDIT, row)
                      }
                    >
                      <EditIcon />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={employeeDataFull?.totalElements || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      <ViewDialog
        openDialogue={openViewDialog}
        handleDialogClose={handleDialogueClose}
      />
    </React.Fragment>
  );
}
EmpConfigTable.propTypes = {
  data: PropTypes.array,
  handleDialogueOpen: PropTypes.func,
};

const ViewDialog = ({ openDialogue, handleDialogClose }) => {
  const { employee, isOpen } = openDialogue || {};
  const { address, mobileNo, name, servicesList } = employee;
  const firstLastName = () => {
    try {
      const splitName = name?.split(" ");
      const firstChar = splitName[0][0] || "";
      const lastChar = splitName[1][0] || "";
      return firstChar?.toUpperCase() + lastChar?.toUpperCase() || "";
    } catch (error) {
      return "";
    }
  };
  return (
    <CustomizedDialog
      openDialog={isOpen}
      handleCloseDialog={handleDialogClose}
      title={"Employee Additional Details"}
      // disableClose={buttonLoader}
      icon={<SupervisedUserCircleIcon />}
      maxWidth="sm"
      isActionButtonRequired={false}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={3}>
        <Stack m={2}>
          <Avatar sx={{ width: 56, height: 56 }}>{firstLastName()}</Avatar>
        </Stack>
        <Stack spacing={1}>
          <Stack direction={"row"}>
            <Typography>Address:</Typography>
            <Typography>
              <address>{address}</address>
            </Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography>Mobile No:</Typography>
            <Typography>{mobileNo}</Typography>
          </Stack>
          <Stack direction={"row"}>
            <Typography sx={{ mr: 1 }}>Specialty: </Typography>
            {servicesList
              ? servicesList.map((item, index) => (
                  <Typography key={index} sx={{ mr: 1 }}>
                    {item.serviceName},{" "}
                  </Typography>
                ))
              : "none"}

            {/* {servicesList.map((item, index) => 
            <Typography key={index}>{item.serviceName}</Typography>
            )} */}
          </Stack>
        </Stack>
      </Stack>
    </CustomizedDialog>
  );
};
